import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapa-sonoro',
  templateUrl: './mapa-sonoro.component.html',
  styleUrls: ['./mapa-sonoro.component.css','../../assets/css/css.css']
})
export class MapaSonoroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
