import { Injectable } from '@angular/core';
import { URL_SERVICIOS } from '../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Archivos, Catalogo } from 'src/app/models/catalogo.model';
import Swal from 'sweetalert2';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class CatalogoService {

    public CatalogoId = 0;
    OperacionExitosa = true;

    constructor(public http: HttpClient) { }

    getListaCatalogo() {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/getCatalogo');

    }

    crearCatalogo(catalogo: Catalogo) {
        const json = JSON.stringify(catalogo);
        const params = json;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = URL_SERVICIOS + 'ApiRest/Catalogo/addCatalogoEditorial';
        return this.http.post(url, params, { headers: headers })
            .pipe(
                map((resp: any) => {
                    this.OperacionExitosa = resp.OperacionExitosa;

                    if (resp.OperacionExitosa) {
                        Swal.fire('Información Almacenada Exitosamente, ' + resp.EjecucionProcesoId, resp.Mensaje, 'success');
                        this.CatalogoId = resp.EjecucionProcesoId;

                    }
                    else {
                        Swal.fire('Alerta', resp.Mensaje, 'error');
                    }
                    return resp.usuario;
                }));
    }

    deleteCatalogo(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain');
        return this.http.post(URL_SERVICIOS + 'ApiRest/Catalogo/deleteCatalogoEditorial?CatalogoEditorialId=' + id, headers);
    }

    getCatalogoById(id): Observable<any> {
        // debugger;
        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/getCatalogoById?CatalogoId=' + id);
    }

    getArchivoByCatalogo(CatalogoId: number) {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/getAllCatalogoEditorialDocumentos?CatalogoId=' + CatalogoId);
    }

    addCatalogoArchivo(archivo: Archivos) {
        const json = JSON.stringify(archivo);
        const params = json;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        const url = URL_SERVICIOS + 'ApiRest/Catalogo/addCatalogoDocumento';
        return this.http.post(url, params, { headers: headers })
            .pipe(
                map((resp: any) => {
                    this.OperacionExitosa = resp.OperacionExitosa;

                    if (resp.OperacionExitosa) {
                        Swal.fire('Información Almacenada Exitosamente, ' + resp.EjecucionProcesoId, resp.Mensaje, 'success');
                    }
                    else {
                        Swal.fire('Alerta', resp.Mensaje, 'error');
                    }
                    return resp.usuario;
                }));
    }

    getArchivoById(catalogoEditorialDocumentosId: number): Observable<any> {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/getCatalogoEditorialDocumentoById?_catalogoEditorialDocumentosId=' + catalogoEditorialDocumentosId);
    }

    deleteCatalogoArchivo(id): Observable<any> {
        const headers = new HttpHeaders().set('Content-Type', 'text/plain');
        return this.http.post(URL_SERVICIOS + 'ApiRest/Catalogo/deleteCatalogoEditorialArchivo?_catalogoEditorialArchivoId=' + id, headers);
    }

    downloadMediosDocumentoById(id): Observable<any> {
        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/GetMedioDocumento?catalogoEditorialDocumentoId=' + id,
          { headers: new HttpHeaders().append('Content-Type', 'application/json'), responseType: 'blob', observe: 'response' });
      }

}