import { Component, ViewChild, OnInit } from '@angular/core';
declare const google: any;
import Swal from 'sweetalert2';
import * as mapsData from 'devextreme/dist/js/vectormap-data/world.js';

@Component({
  selector: 'app-poligonos',
  templateUrl: './poligonos.component.html',
  styleUrls: ['./poligonos.component.css']
})
export class PoligonosComponent implements OnInit {

  title = 'Mapa Oferta';
  lat: number = 5.065781;
  lng: number = -75.507820;
  zoom: number = 6;
  loading = false;

  polygonPts: any[] = [];
  ptsList: string;

  ///// dev express map

  worldMap: any = mapsData.world;
  countries: Countries = {
    Russia: { totalArea: 17.12, color: "#1E90FF" },
    Canada: { totalArea: 9.98, color: "#B8860B" },
    China: { totalArea: 9.59, color: "#BDB76B" },
    'United States': { totalArea: 9.52, color: "#FFA07A" },
    Brazil: { totalArea: 8.51, color: "#3CB371" },
    Australia: { totalArea: 7.69, color: "#D8BFD8" },
    India: { totalArea: 3.29, color: "#DB7093" },
    Argentina: { totalArea: 2.78, color: "#FFD700" },
    Kazakhstan: { totalArea: 2.72, color: "#CD5C5C" },
    Algeria: { totalArea: 2.38, color: "#B0C4DE" },
    Colombia: { totalArea: 2.38, color: "#B0C4DE" }
  };

  ////// devexpresss map


  ///////////////////////////
  polygon: any;



  options: any = {
    lat: 5.065781,
    lng: -75.507820,
    zoom: 10,
    fillColor: '#DC143C',
    draggable: true,
    editable: true,
    visible: true
  };

  ////////////////////////


  constructor() {

    this.countries = this.getCountries();
    this.customizeTooltip = this.customizeTooltip.bind(this);
    this.customizeLayers = this.customizeLayers.bind(this);
    this.click = this.click.bind(this);

  }

  customizeTooltip(arg) {
    const name = arg.attribute("name"),
      country = this.countries[name];
    if (country) {
      return {
        text: name + ": " + country.totalArea + "M km&#178",
        color: country.color
      };
    }
  }

  getCountries(): Countries {
    return this.countries;
  }

  click(e) {
    const target = e.target;
    if (target && this.countries[target.attribute("name")]) {
      target.selected(!target.selected());
    }
  }

  customizeLayers(elements) {
    elements.forEach((element) => {
      const country = this.countries[element.attribute("name")];
      if (country) {
        element.applySettings({
          color: country.color,
          hoveredColor: "#e0e000",
          selectedColor: "#008f00"
        });
      };
    });
  }

  ngOnInit() {
  }

  mapReady(event) {
    this.polygonPts = [
      // tslint:disable-next-line: max-line-length
      new google.maps.LatLng(-1.0785154712312766, -69.4313432170981),
      new google.maps.LatLng(-1.0421584161325348, -69.42653206662337),
      new google.maps.LatLng(-1.0510542913162695, -69.42135082765058),
      new google.maps.LatLng(-1.0613038866366593, -69.42061065065447),
      new google.maps.LatLng(-1.0700063732294431, -69.42912268610976),
      new google.maps.LatLng(-1.0785154712312766, -69.4313432170981),
      new google.maps.LatLng(-1.0785154712312766, -69.4313432170981),
      new google.maps.LatLng(-1.1044295424186776, -69.42338631438989),
      new google.maps.LatLng(-1.1316973337427338, -69.41006312845985),
      new google.maps.LatLng(-1.1483287525644985, -69.39285401330022),
      new google.maps.LatLng(-1.1732758807971457, -69.37508976539351),
      new google.maps.LatLng(-1.193388294256024, -69.37323932290323),
      new google.maps.LatLng(-1.2351602299013869, -69.37823551762699),
      new google.maps.LatLng(-1.25643297490597, -69.37934578312117),
      new google.maps.LatLng(-1.2659090158625568, -69.37601498663865),
      new google.maps.LatLng(-1.2910495326861549, -69.3730542786542),
      new google.maps.LatLng(-1.3067140085531657, -69.37397949989933),
      new google.maps.LatLng(-1.333788411286271, -69.3684281724285),
      new google.maps.LatLng(-1.35138677306279, -69.37138888041295),
      new google.maps.LatLng(-1.3716925751126188, -69.37712525213283),
      new google.maps.LatLng(-1.3937388744810049, -69.38582233183715),
      new google.maps.LatLng(-1.4041818583923455, -69.39544463278662),
      new google.maps.LatLng(-1.4208132772141102, -69.41802003116807),
      new google.maps.LatLng(-1.432609981262106, -69.42967781885685),
      new google.maps.LatLng(-1.4757356370441244, -69.45206817298927),
      new google.maps.LatLng(-1.487919118274022, -69.45428870397761),
      new google.maps.LatLng(-1.5091918632786046, -69.45299339423441),
      new google.maps.LatLng(-1.516927406916635, -69.44818224375967),
      new google.maps.LatLng(-1.5271770022370248, -69.44429631453008),
      new google.maps.LatLng(-1.5525109076515733, -69.44781215526163),
      new google.maps.LatLng(-1.6577143011287832, -69.46724180140959),
      new google.maps.LatLng(-1.7540218194222588, -69.48482100506727),
      new google.maps.LatLng(-1.8659938135827447, -69.50536091670942),
      new google.maps.LatLng(-2.0050402104763365, -69.53015684607921),
      new google.maps.LatLng(-2.1173989818187247, -69.55014162497426),
      new google.maps.LatLng(-2.251804052529498, -69.5740123330989),
      new google.maps.LatLng(-2.321037168089868, -69.5864102977838),
      new google.maps.LatLng(-2.444419089116449, -69.60843056341817),
      new google.maps.LatLng(-2.6250440330644524, -69.64155348399423),
      new google.maps.LatLng(-2.7503598400005407, -69.66468401512276),
      new google.maps.LatLng(-2.874128538209023, -69.68633419225907),
      new google.maps.LatLng(-2.9986707907813086, -69.70798436939538),
      new google.maps.LatLng(-3.1232130433535934, -69.72981959078072),
      new google.maps.LatLng(-3.247755295925879, -69.75165481216605),
      new google.maps.LatLng(-3.3982116196855654, -69.77793109552806),
      new google.maps.LatLng(-3.4968398010704496, -69.79606543193285),
      new google.maps.LatLng(-3.621188665051784, -69.81919596306138),
      new google.maps.LatLng(-3.745730917624069, -69.84214144994088),
      new google.maps.LatLng(-3.842038435917545, -69.8599056978476),
      new google.maps.LatLng(-3.994041868404837, -69.88951277769212),
      new google.maps.LatLng(-4.161709776759141, -69.9220805655211),
      new google.maps.LatLng(-4.20000071776739, -69.92615153899972),
      new google.maps.LatLng(-4.2125709761791885, -69.9292972912332),
      new google.maps.LatLng(-4.2218536285448245, -69.93669906119433),
      new google.maps.LatLng(-4.22842884063715, -69.94946711437728),
      new google.maps.LatLng(-4.222433794317677, -69.95187268961465),
      new google.maps.LatLng(-4.218372633907711, -69.95631375159132),
      new google.maps.LatLng(-4.213344530542991, -69.96778649503108),
      new google.maps.LatLng(-4.21044370167873, -69.98388534469655),
      new google.maps.LatLng(-4.207349484223518, -69.98999180491447),
      new google.maps.LatLng(-4.1972932774940785, -70.0012795041052),
      new google.maps.LatLng(-4.174860200943791, -70.01534286703135),
      new google.maps.LatLng(-4.168671766033367, -70.01811853076677),
      new google.maps.LatLng(-4.149332906938292, -70.02292968124151),
      new google.maps.LatLng(-4.141790751891213, -70.02737074321819),
      new google.maps.LatLng(-4.133281653889379, -70.03458746893028),
      new google.maps.LatLng(-4.116263457885713, -70.0464303008681),
      new google.maps.LatLng(-4.10504691961057, -70.05346198233117),
      new google.maps.LatLng(-4.086094837697396, -70.06067870804327),
      new google.maps.LatLng(-4.080293179968873, -70.06400950452579),
      new google.maps.LatLng(-4.06927003028468, -70.0752972037165),
      new google.maps.LatLng(-4.05902043496429, -70.10416410656492),
      new google.maps.LatLng(-4.05902043496429, -70.13562162889973),
      new google.maps.LatLng(-4.055152663145275, -70.1452439298492),
      new google.maps.LatLng(-4.049351005416752, -70.15320083255742),
      new google.maps.LatLng(-4.036974135595904, -70.163563310503),
      new google.maps.LatLng(-4.010866675817552, -70.17355569995053),
      new google.maps.LatLng(-3.9859195475849054, -70.176701452184),
      new google.maps.LatLng(-3.976056729446417, -70.17448092119567),
      new google.maps.LatLng(-3.9654203569441253, -70.17485100969373),
      new google.maps.LatLng(-3.939699674347675, -70.17873693892332),
      new google.maps.LatLng(-3.916106266251683, -70.18502844339028),
      new google.maps.LatLng(-3.9103046085231608, -70.18724897437862),
      new google.maps.LatLng(-3.905276505158441, -70.19409561159266),
      new google.maps.LatLng(-3.903536007839884, -70.20649357627755),
      new google.maps.LatLng(-3.8961872413837555, -70.23073437290026),
      new google.maps.LatLng(-3.8894186407004794, -70.24535286857349),
      new google.maps.LatLng(-3.882650040017203, -70.25756578900936),
      new google.maps.LatLng(-3.874721107788222, -70.26515260321952),
      new google.maps.LatLng(-3.8646649010587826, -70.26940862094717),
      new google.maps.LatLng(-3.8373971097347264, -70.28606260335971),
      new google.maps.LatLng(-3.8267607372324353, -70.29364941756987),
      new google.maps.LatLng(-3.815737587548242, -70.30382685126644),
      new google.maps.LatLng(-3.810902872774473, -70.31270897521979),
      new google.maps.LatLng(-3.8052946036369013, -70.32695738239497),
      new google.maps.LatLng(-3.804134272091197, -70.34083570107208),
      new google.maps.LatLng(-3.804327660682148, -70.34916269227836),
      new google.maps.LatLng(-3.808582209683064, -70.36267092245743),
      new google.maps.LatLng(-3.81515742177539, -70.37155304641078),
      new google.maps.LatLng(-3.8317888405971545, -70.38580145358596),
      new google.maps.LatLng(-3.841651658735643, -70.39505366603737),
      new google.maps.LatLng(-3.8499673681465256, -70.40134517050433),
      new google.maps.LatLng(-3.8592500205121616, -70.41781410866784),
      new google.maps.LatLng(-3.8592500205121616, -70.42447570163287),
      new google.maps.LatLng(-3.8617640721945214, -70.43224756009205),
      new google.maps.LatLng(-3.8664053983773394, -70.43742879906485),
      new google.maps.LatLng(-3.8766549936977297, -70.45445286997544),
      new google.maps.LatLng(-3.8814897084714985, -70.46629570191325),
      new google.maps.LatLng(-3.8847773145176614, -70.48535525956316),
      new google.maps.LatLng(-3.883423594381006, -70.50774561369559),
      new google.maps.LatLng(-3.880909542698646, -70.5182931358902),
      new google.maps.LatLng(-3.875688050742976, -70.52847056958676),
      new google.maps.LatLng(-3.868532672877798, -70.53864800328331),
      new google.maps.LatLng(-3.8528681970107868, -70.55160110071529),
      new google.maps.LatLng(-3.838750829871382, -70.56751490613172),
      new google.maps.LatLng(-3.832369006370007, -70.58194835755593),
      new google.maps.LatLng(-3.83314256073381, -70.59194074700346),
      new google.maps.LatLng(-3.8381706640985294, -70.59971260546264),
      new google.maps.LatLng(-3.8433921560542, -70.61211057014754),
      new google.maps.LatLng(-3.8368169439618742, -70.62432349058341),
      new google.maps.LatLng(-3.8319822291881054, -70.63005986230328),
      new google.maps.LatLng(-3.830048343278598, -70.64726897746291),
      new google.maps.LatLng(-3.821345856685814, -70.66059216339295),
      new google.maps.LatLng(-3.809355764046867, -70.6707695970895),
      new google.maps.LatLng(-3.7981392257717235, -70.67835641129966),
      new google.maps.LatLng(-3.7942714539527085, -70.68390773877051),
      new google.maps.LatLng(-3.7925309566341516, -70.69464030521415),
      new google.maps.LatLng(-3.79465823113461, -70.70333738491848),
      new google.maps.LatLng(-3.752306129716395, -70.67706110155646),
      new google.maps.LatLng(-3.649230010739643, -70.61007508340823),
      new google.maps.LatLng(-3.557757207219937, -70.55086092371918),
      new google.maps.LatLng(-3.4602893573807574, -70.48776083480054),
      new google.maps.LatLng(-3.4417240526494846, -70.47499278161759),
      new google.maps.LatLng(-3.3862215270466187, -70.44001941855124),
      new google.maps.LatLng(-3.2866264027069807, -70.37506888714232),
      new google.maps.LatLng(-3.1965073193239295, -70.31696499294743),
      new google.maps.LatLng(-3.1280477581273622, -70.27162915193551),
      new google.maps.LatLng(-2.9922889672799338, -70.1826228681529),
      new google.maps.LatLng(-2.8808971388922995, -70.11008552253382),
      new google.maps.LatLng(-2.7921317756459034, -70.05290684958409),
      new google.maps.LatLng(-2.76041604672998, -70.0340323361832),
      new google.maps.LatLng(-2.7418507419987077, -70.03421738043224),
      new google.maps.LatLng(-2.7445581822720184, -70.04717047786421),
      new google.maps.LatLng(-2.7426242963625107, -70.05383207082923),
      new google.maps.LatLng(-2.736049084270185, -70.06530481426898),
      new google.maps.LatLng(-2.73218131245117, -70.06900569924954),
      new google.maps.LatLng(-2.7105217902646856, -70.07252153998108),
      new google.maps.LatLng(-2.6784192841668606, -70.0706710974908),
      new google.maps.LatLng(-2.6697167975740763, -70.07215145148302),
      new google.maps.LatLng(-2.659273813662736, -70.07844295594998),
      new google.maps.LatLng(-2.644189503568577, -70.09287640737419),
      new google.maps.LatLng(-2.6484440525694937, -70.10231366407464),
      new google.maps.LatLng(-2.6544390988889672, -70.104719239312),
      new google.maps.LatLng(-2.6766787868483037, -70.10971543403576),
      new google.maps.LatLng(-2.695244091579576, -70.1197078234833),
      new google.maps.LatLng(-2.703753189581409, -70.13340109791139),
      new google.maps.LatLng(-2.705687075490917, -70.14191313336669),
      new google.maps.LatLng(-2.704913521127114, -70.15024012457296),
      new google.maps.LatLng(-2.697951531852887, -70.15986242552243),
      new google.maps.LatLng(-2.689829211032955, -70.16300817775591),
      new google.maps.LatLng(-2.681126724440171, -70.16337826625397),
      new google.maps.LatLng(-2.6635283626636523, -70.15930729277534),
      new google.maps.LatLng(-2.6573399277532284, -70.1589372042773),
      new google.maps.LatLng(-2.63974156597671, -70.16374835475203),
      new google.maps.LatLng(-2.630458913611074, -70.17281552295441),
      new google.maps.LatLng(-2.629298582065369, -70.1813275584097),
      new google.maps.LatLng(-2.633939908248187, -70.18558357613736),
      new google.maps.LatLng(-2.643609337795725, -70.18817419562376),
      new google.maps.LatLng(-2.6494109955242475, -70.19224516910238),
      new google.maps.LatLng(-2.6538589331161146, -70.20112729305573),
      new google.maps.LatLng(-2.6499911612971, -70.21352525774063),
      new google.maps.LatLng(-2.6403217317495624, -70.21907658521148),
      new google.maps.LatLng(-2.6263977532011076, -70.22407277993524),
      new google.maps.LatLng(-2.6159547692897673, -70.22592322242552),
      new google.maps.LatLng(-2.600290293422756, -70.2257381781765),
      new google.maps.LatLng(-2.573796056462503, -70.22999419590414),
      new google.maps.LatLng(-2.553490254412674, -70.24220711634001),
      new google.maps.LatLng(-2.5490423168208065, -70.24831357655795),
      new google.maps.LatLng(-2.5438208248651364, -70.26089658549186),
      new google.maps.LatLng(-2.5436274362741855, -70.26996375369426),
      new google.maps.LatLng(-2.545948099365594, -70.27403472717288),
      new google.maps.LatLng(-2.5592919121411963, -70.28680278035583),
      new google.maps.LatLng(-2.5728291135077495, -70.30678755925088),
      new google.maps.LatLng(-2.5755365537810597, -70.31640986020035),
      new google.maps.LatLng(-2.5759233309629614, -70.33287879836386),
      new google.maps.LatLng(-2.5730225020987, -70.34213101081528),
      new google.maps.LatLng(-2.5668340671882754, -70.35045800202155),
      new google.maps.LatLng(-2.5569712490497873, -70.35693455073755),
      new google.maps.LatLng(-2.5480753738660527, -70.35656446223949),
      new google.maps.LatLng(-2.5411133845918252, -70.35045800202155),
      new google.maps.LatLng(-2.53705222418186, -70.34250109931334),
      new google.maps.LatLng(-2.535118338272352, -70.33028817887748),
      new google.maps.LatLng(-2.536665446999958, -70.3265872938969),
      new google.maps.LatLng(-2.5345381724995, -70.31418932921201),
      new google.maps.LatLng(-2.526029074497667, -70.30086614328198),
      new google.maps.LatLng(-2.5194538624053413, -70.29809047954656),
      new google.maps.LatLng(-2.5119117073582617, -70.29790543529752),
      new google.maps.LatLng(-2.5034026093564288, -70.30123623178004),
      new google.maps.LatLng(-2.501468723446921, -70.30530720525866),
      new google.maps.LatLng(-2.503209220765478, -70.31529959470618),
      new google.maps.LatLng(-2.502822443583576, -70.32307145316537),
      new google.maps.LatLng(-2.4935397912179402, -70.35378879850407),
      new google.maps.LatLng(-2.4935397912179402, -70.36618676318896),
      new google.maps.LatLng(-2.495860454309349, -70.373033400403),
      new google.maps.LatLng(-2.5041761637202313, -70.38728180757818),
      new google.maps.LatLng(-2.521194359723898, -70.40763667497129),
      new google.maps.LatLng(-2.5252555201338636, -70.41762906441882),
      new google.maps.LatLng(-2.5190670852234396, -70.4279915423644),
      new google.maps.LatLng(-2.507076992584493, -70.43798393181193),
      new google.maps.LatLng(-2.495473677127448, -70.44094463979638),
      new google.maps.LatLng(-2.4887050764441714, -70.44075959554735),
      new google.maps.LatLng(-2.4798092012604367, -70.44464552477694),
      new google.maps.LatLng(-2.4656918341210314, -70.45297251598322),
      new google.maps.LatLng(-2.4564091817553955, -70.46111446294046),
      new google.maps.LatLng(-2.453701741482085, -70.46574056916617),
      new google.maps.LatLng(-2.4550554616187403, -70.46981154264479),
      new google.maps.LatLng(-2.4604703421653613, -70.47462269311953),
      new google.maps.LatLng(-2.4689794401671943, -70.48720570205344),
      new google.maps.LatLng(-2.469559605940047, -70.49571773750876),
      new google.maps.LatLng(-2.464918279757229, -70.50811570219365),
      new google.maps.LatLng(-2.46008356498346, -70.51514738365672),
      new google.maps.LatLng(-2.4517678555725775, -70.52254915361786),
      new google.maps.LatLng(-2.444999254889301, -70.52513977310424),
      new google.maps.LatLng(-2.4366835454784184, -70.53180136606926),
      new google.maps.LatLng(-2.4274008931127824, -70.53753773778914),
      new google.maps.LatLng(-2.4196653494747524, -70.54845534848181),
      new google.maps.LatLng(-2.4154108004738357, -70.56196357866088),
      new google.maps.LatLng(-2.4186984065199986, -70.57861756107341),
      new google.maps.LatLng(-2.4252736186123243, -70.58361375579719),
      new google.maps.LatLng(-2.4320422192956004, -70.58102313631079),
      new google.maps.LatLng(-2.440164540115532, -70.56584950789048),
      new google.maps.LatLng(-2.448093472344513, -70.55733747243517),
      new google.maps.LatLng(-2.4562157931644446, -70.55178614496432),
      new google.maps.LatLng(-2.464724891166278, -70.54901048122889),
      new google.maps.LatLng(-2.4705265488948003, -70.55049083522113),
      new google.maps.LatLng(-2.4763282066233234, -70.56288879990602),
      new google.maps.LatLng(-2.477295149578077, -70.56788499462978),
      new google.maps.LatLng(-2.4846439160342055, -70.58342871154815),
      new google.maps.LatLng(-2.483483584488501, -70.59564163198402),
      new google.maps.LatLng(-2.4805827556242397, -70.60822464091794),
      new google.maps.LatLng(-2.473814154940963, -70.61969738435769),
      new google.maps.LatLng(-2.46781910862149, -70.62506366757952),
      new google.maps.LatLng(-2.4546686844368386, -70.62857950831105),
      new google.maps.LatLng(-2.434749659568911, -70.62376835783633),
      new google.maps.LatLng(-2.412123194427673, -70.61914225161061),
      new google.maps.LatLng(-2.4045810393805933, -70.61877216311255),
      new google.maps.LatLng(-2.397232272924465, -70.62284313659117),
      new google.maps.LatLng(-2.4092223655634117, -70.6393120747547),
      new google.maps.LatLng(-2.410962862881968, -70.6452334907236),
      new google.maps.LatLng(-2.4099959199272147, -70.65559596866919),
      new google.maps.LatLng(-2.407481868244855, -70.65985198639683),
      new google.maps.LatLng(-2.398972770243022, -70.66725375635797),
      new google.maps.LatLng(-2.3927843353325975, -70.66891915459922),
      new google.maps.LatLng(-2.382147962830306, -70.66466313687157),
      new google.maps.LatLng(-2.368030595690901, -70.65633614566529),
      new google.maps.LatLng(-2.3606818292347724, -70.65522588017113),
      new google.maps.LatLng(-2.3510123996872347, -70.65818658815559),
      new google.maps.LatLng(-2.340376027184943, -70.67428543782104),
      new google.maps.LatLng(-2.3396024728211406, -70.68335260602342),
      new google.maps.LatLng(-2.335734701002125, -70.69741596894957),
      new google.maps.LatLng(-2.3324470949559624, -70.71684561509754),
      new google.maps.LatLng(-2.329739654682652, -70.72554269480187),
      new google.maps.LatLng(-2.3216173338627204, -70.74108641172025),
      new google.maps.LatLng(-2.314075178815641, -70.74682278344012),
      new google.maps.LatLng(-2.2980239257667283, -70.75459464189932),
      new google.maps.LatLng(-2.288741273401092, -70.76292163310558),
      new google.maps.LatLng(-2.2862272217187325, -70.77217384555699),
      new google.maps.LatLng(-2.2906751593105996, -70.78845773947148),
      new google.maps.LatLng(-2.292802433811058, -70.80363136789181),
      new google.maps.LatLng(-2.2933825995839103, -70.81917508481018),
      new google.maps.LatLng(-2.2914487136744026, -70.82824225301256),
      new google.maps.LatLng(-2.2883544962191906, -70.83490384597758),
      new google.maps.LatLng(-2.2782982894897517, -70.84193552744065),
      new google.maps.LatLng(-2.24967677802904, -70.85026251864693),
      new google.maps.LatLng(-2.2357527994805855, -70.85525871337069),
      new google.maps.LatLng(-2.2270503128878016, -70.86414083732404),
      new google.maps.LatLng(-2.222215598114033, -70.87450331526964),
      new google.maps.LatLng(-2.2208618779773777, -70.88134995248367),
      new google.maps.LatLng(-2.2220222095230824, -70.88875172244481),
      new google.maps.LatLng(-2.237106519617241, -70.89929924463942),
      new google.maps.LatLng(-2.243294954527665, -70.91114207657724),
      new google.maps.LatLng(-2.245809006210025, -70.91983915628157),
      new google.maps.LatLng(-2.245422229028123, -70.9279811032388),
      new google.maps.LatLng(-2.240200737072453, -70.93760340418828),
      new google.maps.LatLng(-2.234979245116783, -70.9429696874101),
      new google.maps.LatLng(-2.216607328976461, -70.95129667861637),
      new google.maps.LatLng(-2.207904842383677, -70.95980871407167),
      new google.maps.LatLng(-2.205584179292268, -70.96776561677989),
      new google.maps.LatLng(-2.204230459155613, -70.98701021867882),
      new google.maps.LatLng(-2.205197402110367, -70.99293163464773),
      new google.maps.LatLng(-2.220668489386427, -71.00218384709915),
      new google.maps.LatLng(-2.2278238672516046, -71.0038492453404),
      new google.maps.LatLng(-2.233238747798226, -71.00218384709915),
      new google.maps.LatLng(-2.247356114937631, -70.99311667889675),
      new google.maps.LatLng(-2.2579924874399224, -70.98867561692008),
      new google.maps.LatLng(-2.2666949740327063, -70.98330933369826),
      new google.maps.LatLng(-2.2852602787639786, -70.97757296197838),
      new google.maps.LatLng(-2.2908685479015505, -70.97868322747256),
      new google.maps.LatLng(-2.297637148584827, -70.98571490893563),
      new google.maps.LatLng(-2.297637148584827, -70.99348676739481),
      new google.maps.LatLng(-2.2848735015820774, -71.00792021881902),
      new google.maps.LatLng(-2.2711429116245734, -71.02050322775294),
      new google.maps.LatLng(-2.2680486941693614, -71.02753490921602),
      new google.maps.LatLng(-2.263987533759396, -71.04622437836787),
      new google.maps.LatLng(-2.2636007565774943, -71.05177570583872),
      new google.maps.LatLng(-2.2665015854417554, -71.06509889176876),
      new google.maps.LatLng(-2.2694024143060165, -71.07361092722405),
      new google.maps.LatLng(-2.2736569633069337, -71.07805198920073),
      new google.maps.LatLng(-2.278685066671653, -71.09970216633704),
      new google.maps.LatLng(-2.282359449899717, -71.10432827256275),
      new google.maps.LatLng(-2.2844867244001756, -71.11802154699085),
      new google.maps.LatLng(-2.2933825995839103, -71.12616349394808),
      new google.maps.LatLng(-2.3003445888581373, -71.12801393643838),
      new google.maps.LatLng(-2.3165892304980007, -71.11802154699085),
      new google.maps.LatLng(-2.329739654682652, -71.11302535226709),
      new google.maps.LatLng(-2.336121478184027, -71.11487579475737),
      new google.maps.LatLng(-2.3401826385939923, -71.11894676823599),
      new google.maps.LatLng(-2.340569415775894, -71.12616349394808),
      new google.maps.LatLng(-2.336121478184027, -71.13245499841506),
      new google.maps.LatLng(-2.337281809729731, -71.14633331709217),
      new google.maps.LatLng(-2.3417297473215983, -71.15188464456303),
      new google.maps.LatLng(-2.3674504299180485, -71.15706588353581),
      new google.maps.LatLng(-2.371318201737064, -71.15891632602609),
      new google.maps.LatLng(-2.375766139328931, -71.164837741995),
      new google.maps.LatLng(-2.374412419192276, -71.17446004294447),
      new google.maps.LatLng(-2.368223984281852, -71.18593278638423),
      new google.maps.LatLng(-2.363776046689985, -71.19092898110799),
      new google.maps.LatLng(-2.346951239277269, -71.20295685729482),
      new google.maps.LatLng(-2.340956192957796, -71.21331933524041),
      new google.maps.LatLng(-2.3417297473215983, -71.22016597245445),
      new google.maps.LatLng(-2.34985206814153, -71.23644986636894),
      new google.maps.LatLng(-2.3488851251867766, -71.24440676907716),
      new google.maps.LatLng(-2.337861975502584, -71.26420650372319),
      new google.maps.LatLng(-2.337281809729731, -71.27068305243918),
      new google.maps.LatLng(-2.3386355298663863, -71.28437632686726),
      new google.maps.LatLng(-2.347724793641072, -71.2917780968284),
      new google.maps.LatLng(-2.358747943325265, -71.29455376056382),
      new google.maps.LatLng(-2.3686107614637537, -71.29825464554439),
      new google.maps.LatLng(-2.3763463051017837, -71.30510128275843),
      new google.maps.LatLng(-2.375959527919882, -71.33008225637725),
      new google.maps.LatLng(-2.379247133966045, -71.341554999817),
      new google.maps.LatLng(-2.3833082943760107, -71.34840163703105),
      new google.maps.LatLng(-2.3981992158792185, -71.3663509291868),
      new google.maps.LatLng(-2.397812438697317, -71.37486296464209),
      new google.maps.LatLng(-2.3951049984240065, -71.38096942486003),
      new google.maps.LatLng(-2.3914306151959424, -71.38356004434642),
      new google.maps.LatLng(-2.3809876312846017, -71.38596561958379),
      new google.maps.LatLng(-2.3548801715062497, -71.37652836288335),
      new google.maps.LatLng(-2.346564462095367, -71.37615827438529),
      new google.maps.LatLng(-2.3307065976374055, -71.3913319028056),
      new google.maps.LatLng(-2.311754515724232, -71.40576535422981),
      new google.maps.LatLng(-2.307693355314266, -71.41131668170067),
      new google.maps.LatLng(-2.2908685479015505, -71.42019880565402),
      new google.maps.LatLng(-2.2798453982173577, -71.42871084110932),
      new google.maps.LatLng(-2.267081751214608, -71.44147889429227),
      new google.maps.LatLng(-2.2605065391222823, -71.44925075275145),
      new google.maps.LatLng(-2.2610867048951344, -71.45554225721843),
      new google.maps.LatLng(-2.2665015854417554, -71.47034579714068),
      new google.maps.LatLng(-2.272496631761229, -71.4762672131096),
      new google.maps.LatLng(-2.284099947218274, -71.4783026998489),
      new google.maps.LatLng(-2.2984107029486296, -71.47460181486834),
      new google.maps.LatLng(-2.3105941841785276, -71.46738508915624),
      new google.maps.LatLng(-2.325678494272686, -71.46220385018344),
      new google.maps.LatLng(-2.333607426501667, -71.4684953546504),
      new google.maps.LatLng(-2.338248752684485, -71.47534199186445),
      new google.maps.LatLng(-2.336508255365928, -71.48366898307071),
      new google.maps.LatLng(-2.3245181627269815, -71.49329128402019),
      new google.maps.LatLng(-2.3158156761341977, -71.49699216900075),
      new google.maps.LatLng(-2.297637148584827, -71.5005080097323),
      new google.maps.LatLng(-2.2587660418037254, -71.49976783273618),
      new google.maps.LatLng(-2.2527709954842523, -71.498657567242),
      new google.maps.LatLng(-2.2446486746643206, -71.50032296548326),
      new google.maps.LatLng(-2.238460239753896, -71.50753969119536),
      new google.maps.LatLng(-2.2390404055267483, -71.52215818686861),
      new google.maps.LatLng(-2.2313048618887183, -71.54140278876754),
      new google.maps.LatLng(-2.2313048618887183, -71.5482494259816),
      new google.maps.LatLng(-2.2291775873882598, -71.55990721367037),
      new google.maps.LatLng(-2.2262767585239986, -71.56434827564705),
      new google.maps.LatLng(-2.2253098155692452, -71.57304535535138),
      new google.maps.LatLng(-2.2164139403855105, -71.59228995725032),
      new google.maps.LatLng(-2.2046172363375143, -71.60635332017647),
      new google.maps.LatLng(-2.1959147497447304, -71.61782606361622),
      new google.maps.LatLng(-2.1906932577890603, -71.62837358581083),
      new google.maps.LatLng(-2.1930139208804693, -71.64872845320394),
      new google.maps.LatLng(-2.1964949155175826, -71.66242172763204),
      new google.maps.LatLng(-2.212739557157446, -71.68536721451154),
      new google.maps.LatLng(-2.2156403860217075, -71.6877727897489),
      new google.maps.LatLng(-2.2179610491131165, -71.6988754446906),
      new google.maps.LatLng(-2.214286665885052, -71.70683234739882),
      new google.maps.LatLng(-2.207904842383677, -71.71460420585801),
      new google.maps.LatLng(-2.2021031846551544, -71.71645464834829),
      new google.maps.LatLng(-2.1922403665166663, -71.71441916160899),
      new google.maps.LatLng(-2.1609114147826443, -71.70479686065951),
      new google.maps.LatLng(-2.150081653689402, -71.70553703765563),
      new google.maps.LatLng(-2.1369312295047505, -71.70849774564007),
      new google.maps.LatLng(-2.1290022972757696, -71.71719482534441),
      new google.maps.LatLng(-2.1284221315029175, -71.72145084307206),
      new google.maps.LatLng(-2.1317097375490803, -71.73070305552346),
      new google.maps.LatLng(-2.143506441597076, -71.74014031222391),
      new google.maps.LatLng(-2.15472297987222, -71.74180571046517),
      new google.maps.LatLng(-2.1643924094197575, -71.75142801141463),
      new google.maps.LatLng(-2.168840347011625, -71.76216057785828),
      new google.maps.LatLng(-2.1707742329211324, -71.77104270181164),
      new google.maps.LatLng(-2.174061838967295, -71.77622394078442),
      new google.maps.LatLng(-2.1951411953809274, -71.79435827718919),
      new google.maps.LatLng(-2.1951411953809274, -71.79805916216976),
      new google.maps.LatLng(-2.185278377242439, -71.81323279059008),
      new google.maps.LatLng(-2.1870188745609958, -71.82229995879247),
      new google.maps.LatLng(-2.197848635654238, -71.82729615351623),
      new google.maps.LatLng(-2.207904842383677, -71.82859146325943),
      new google.maps.LatLng(-2.237880073981044, -71.8415445606914),
      new google.maps.LatLng(-2.278685066671653, -71.851907038637),
      new google.maps.LatLng(-2.3003445888581373, -71.86097420683937),
      new google.maps.LatLng(-2.307113189541414, -71.86874606529857),
      new google.maps.LatLng(-2.309433852632823, -71.87577774676164),
      new google.maps.LatLng(-2.3090470754509216, -71.88243933972666),
      new google.maps.LatLng(-2.3134950130427887, -71.8892859769407),
      new google.maps.LatLng(-2.3134950130427887, -71.90001854338433),
      new google.maps.LatLng(-2.3096272412237733, -71.91593234880078),
      new google.maps.LatLng(-2.311561127133281, -71.91907810103426),
      new google.maps.LatLng(-2.3185231164075084, -71.9212986320226),
      new google.maps.LatLng(-2.327225603000292, -71.91907810103426),
      new google.maps.LatLng(-2.3573942231886096, -71.90334933986685),
      new google.maps.LatLng(-2.3647429896447383, -71.90186898587463),
      new google.maps.LatLng(-2.3734454762375226, -71.90723526909645),
      new google.maps.LatLng(-2.3747991963741777, -71.91389686206146),
      new google.maps.LatLng(-2.37035125878231, -71.91833792403814),
      new google.maps.LatLng(-2.3570074460067083, -71.92555464975024),
      new google.maps.LatLng(-2.3521727312329395, -71.93351155245846),
      new google.maps.LatLng(-2.3575876117795604, -71.950165534871),
      new google.maps.LatLng(-2.3631958809171323, -71.96182332255978),
      new google.maps.LatLng(-2.3655165440085413, -71.97348111024857),
      new google.maps.LatLng(-2.363776046689985, -71.98384358819415),
      new google.maps.LatLng(-2.3560405030519544, -71.99642659712808),
      new google.maps.LatLng(-2.3508190110962843, -72.00031252635767),
      new google.maps.LatLng(-2.3326404835469132, -72.02029730525273),
      new google.maps.LatLng(-2.329739654682652, -72.03158500444344),
      new google.maps.LatLng(-2.3310933748193072, -72.03510084517498),
      new google.maps.LatLng(-2.339989250003042, -72.04213252663806),
      new google.maps.LatLng(-2.3519793426419886, -72.04990438509725),
      new google.maps.LatLng(-2.3732520876465717, -72.06544810201562),
      new google.maps.LatLng(-2.3848554031036167, -72.07988155343983),
      new google.maps.LatLng(-2.3920107809687945, -72.09042907563443),
      new google.maps.LatLng(-2.4038074850167908, -72.10282704031934),
      new google.maps.LatLng(-2.4119298058367225, -72.1091185447863),
      new google.maps.LatLng(-2.42159923538426, -72.11466987225714),
      new google.maps.LatLng(-2.4260471729761273, -72.11578013775132),
      new google.maps.LatLng(-2.4335893280232064, -72.11466987225714),
      new google.maps.LatLng(-2.440164540115532, -72.1176305802416),
      new google.maps.LatLng(-2.4515744669816266, -72.1163352704984),
      new google.maps.LatLng(-2.4625976166658194, -72.1209613767241),
      new google.maps.LatLng(-2.468399274394342, -72.12558748294981),
      new google.maps.LatLng(-2.469366217349096, -72.1359499608954),
      new google.maps.LatLng(-2.465498445530081, -72.1426115538604),
      new google.maps.LatLng(-2.4566025703463463, -72.14538721759584),
      new google.maps.LatLng(-2.441131483070286, -72.14372181935458),
      new google.maps.LatLng(-2.433976105205108, -72.14649748309),
      new google.maps.LatLng(-2.4272075045218315, -72.15537960704336),
      new google.maps.LatLng(-2.4283678360675363, -72.16204120000839),
      new google.maps.LatLng(-2.441711648843138, -72.17610456293453),
      new google.maps.LatLng(-2.444419089116449, -72.18406146564274),
      new google.maps.LatLng(-2.443645534752646, -72.19275854534708),
      new google.maps.LatLng(-2.439971151524581, -72.20626677552615),
      new google.maps.LatLng(-2.4330091622503542, -72.2210703154484),
      new google.maps.LatLng(-2.4345562709779602, -72.2321729703901),
      new google.maps.LatLng(-2.4368769340693692, -72.24012987309831),
      new google.maps.LatLng(-2.442871980388843, -72.24179527133957),
      new google.maps.LatLng(-2.4540885186639865, -72.23883456335511),
      new google.maps.LatLng(-2.46008356498346, -72.24272049258471),
      new google.maps.LatLng(-2.460663730756312, -72.24771668730847),
      new google.maps.LatLng(-2.4566025703463463, -72.257709076756),
      new google.maps.LatLng(-2.4575695133010997, -72.26215013873268),
      new google.maps.LatLng(-2.470913326076702, -72.27306774942535),
      new google.maps.LatLng(-2.4759414294414217, -72.27935925389231),
      new google.maps.LatLng(-2.4776819267599786, -72.2845404928651),
      new google.maps.LatLng(-2.477101760987126, -72.2956431478068),
      new google.maps.LatLng(-2.4821298643518457, -72.31044668772905),
      new google.maps.LatLng(-2.4840637502613534, -72.32636049314549),
      new google.maps.LatLng(-2.4902521851717774, -72.34134907731678),
      new google.maps.LatLng(-2.4904455737627282, -72.34597518354249),
      new google.maps.LatLng(-2.4846439160342055, -72.35337695350361),
      new google.maps.LatLng(-2.4888984650351222, -72.36484969694337),
      new google.maps.LatLng(-2.489091853626073, -72.37114120141034),
      new google.maps.LatLng(-2.485030693216107, -72.37817288287341),
      new google.maps.LatLng(-2.4767149838052247, -72.38150367935592),
      new google.maps.LatLng(-2.4581496790739523, -72.37835792712244),
      new google.maps.LatLng(-2.451187689799725, -72.3813186351069),
      new google.maps.LatLng(-2.442871980388843, -72.38798022807191),
      new google.maps.LatLng(-2.43436288238701, -72.39760252902138),
      new google.maps.LatLng(-2.441324871661237, -72.40944536095918),
      new google.maps.LatLng(-2.4391975971607787, -72.42350872388533),
      new google.maps.LatLng(-2.4314620535227482, -72.43886739655468),
      new google.maps.LatLng(-2.4314620535227482, -72.45367093647694),
      new google.maps.LatLng(-2.433202550841305, -72.46699412240699),
      new google.maps.LatLng(-2.4314620535227482, -72.48568359155884),
      new google.maps.LatLng(-2.4322356078865512, -72.49549093675734),
      new google.maps.LatLng(-2.430881887749896, -72.49882173323985),
      new google.maps.LatLng(-2.432815773659404, -72.51843642363684),
      new google.maps.LatLng(-2.430881887749896, -72.5299091670766),
      new google.maps.LatLng(-2.42933477902229, -72.54637810524011),
      new google.maps.LatLng(-2.4256603957942255, -72.55822093717792),
      new google.maps.LatLng(-2.4202455152476046, -72.56192182215848),
      new google.maps.LatLng(-2.4069017024720027, -72.57450483109241),
      new google.maps.LatLng(-2.397232272924465, -72.58653270727925),
      new google.maps.LatLng(-2.3949116098330556, -72.59708022947386),
      new google.maps.LatLng(-2.395685164196859, -72.61891545085919),
      new google.maps.LatLng(-2.3991661588339728, -72.62798261906158),
      new google.maps.LatLng(-2.4061281481081993, -72.62853775180866),
      new google.maps.LatLng(-2.4152174118828853, -72.62446677833005),
      new google.maps.LatLng(-2.422179401157112, -72.62428173408102),
      new google.maps.LatLng(-2.427787670294684, -72.6261321765713),
      new google.maps.LatLng(-2.424693452839472, -72.66443633612015),
      new google.maps.LatLng(-2.434943048159862, -72.66499146886724),
      new google.maps.LatLng(-2.441711648843138, -72.66776713260266),
      new google.maps.LatLng(-2.449447192481168, -72.6777595220502),
      new google.maps.LatLng(-2.441324871661237, -72.67942492029144),
      new google.maps.LatLng(-2.430108333386093, -72.6883070442448),
      new google.maps.LatLng(-2.4202455152476046, -72.68756686724869),
      new google.maps.LatLng(-2.4076752568358053, -72.7125478408675),
      new google.maps.LatLng(-2.4012934333344305, -72.73234757551353),
      new google.maps.LatLng(-2.409609142745313, -72.73234757551353),
      new google.maps.LatLng(-2.4198587380657033, -72.73530828349799),
      new google.maps.LatLng(-2.4241132870666195, -72.73974934547466),
      new google.maps.LatLng(-2.426433950158029, -72.74992677917122),
      new google.maps.LatLng(-2.4192785722928507, -72.75621828363818),
      new google.maps.LatLng(-2.41134964006387, -72.75788368187943),
      new google.maps.LatLng(-2.402066987698234, -72.75806872612846),
      new google.maps.LatLng(-2.3922041695597454, -72.76362005359931),
      new google.maps.LatLng(-2.385242180285518, -72.77842359352157),
      new google.maps.LatLng(-2.3891099521045334, -72.78527023073562),
      new google.maps.LatLng(-2.4055479823353476, -72.79859341666565),
      new google.maps.LatLng(-2.411156251472919, -72.80506996538165),
      new google.maps.LatLng(-2.41405708033718, -72.8113614698486),
      new google.maps.LatLng(-2.4152174118828853, -72.8211688150471),
      new google.maps.LatLng(-2.4204389038385554, -72.83116120449463),
      new google.maps.LatLng(-2.431075276340847, -72.84355916917953),
      new google.maps.LatLng(-2.4353298253417632, -72.85096093914065),
      new google.maps.LatLng(-2.4382306542060244, -72.8616935055843),
      new google.maps.LatLng(-2.4382306542060244, -72.87409147026919),
      new google.maps.LatLng(-2.433202550841305, -72.89148562967785),
      new google.maps.LatLng(-2.435716602523665, -72.89999766513314),
      new google.maps.LatLng(-2.4467397522078578, -72.91776191303987),
      new google.maps.LatLng(-2.4533149643001835, -72.92645899274419),
      new google.maps.LatLng(-2.4500273582540206, -72.93256545296212),
      new google.maps.LatLng(-2.444612477707399, -72.93756164768588),
      new google.maps.LatLng(-2.439971151524581, -72.9357112051956),
      new google.maps.LatLng(-2.430881887749896, -72.92664403699322),
      new google.maps.LatLng(-2.4245000642485213, -72.92275810776363),
      new google.maps.LatLng(-2.4181182407471464, -72.92331324051071),
      new google.maps.LatLng(-2.4134769145643284, -72.9284944794835),
      new google.maps.LatLng(-2.4123165830186237, -72.93478598395046),
      new google.maps.LatLng(-2.4069017024720027, -72.9455185503941),
      new google.maps.LatLng(-2.4022603762891848, -72.95051474511787),
      new google.maps.LatLng(-2.3935578896964005, -72.95477076284551),
      new google.maps.LatLng(-2.383695071557912, -72.95717633808289),
      new google.maps.LatLng(-2.3734454762375226, -72.95810155932803),
      new google.maps.LatLng(-2.3595214976890677, -72.96809394877556),
      new google.maps.LatLng(-2.3504322339143826, -72.97068456826194),
      new google.maps.LatLng(-2.343083467458254, -72.97049952401292),
      new google.maps.LatLng(-2.339215695639239, -72.9723499665032),
      new google.maps.LatLng(-2.339022307048288, -72.98030686921142),
      new google.maps.LatLng(-2.3432768560492048, -72.98456288693907),
      new google.maps.LatLng(-2.3548801715062497, -72.99233474539825),
      new google.maps.LatLng(-2.3585545547343143, -73.00251217909481),
      new google.maps.LatLng(-2.354106617142447, -73.00787846231663),
      new google.maps.LatLng(-2.341923135912549, -73.01768580751514),
      new google.maps.LatLng(-2.32413138554508, -73.02120164824667),
      new google.maps.LatLng(-2.3154288989522964, -73.02693801996655),
      new google.maps.LatLng(-2.313301624451838, -73.03526501117281),
      new google.maps.LatLng(-2.3181363392256067, -73.04081633864367),
      new google.maps.LatLng(-2.3303198204555042, -73.0443321793752),
      new google.maps.LatLng(-2.346564462095367, -73.04359200237909),
      new google.maps.LatLng(-2.3525595084148407, -73.04488731212228),
      new google.maps.LatLng(-2.358941331916216, -73.04988350684606),
      new google.maps.LatLng(-2.360488440643822, -73.05506474581884),
      new google.maps.LatLng(-2.3585545547343143, -73.06302164852706),
      new google.maps.LatLng(-2.3550735600972006, -73.0665374892586),
      new google.maps.LatLng(-2.34463057618586, -73.07023837423917),
      new google.maps.LatLng(-2.337281809729731, -73.06912810874499),
      new google.maps.LatLng(-2.3293528775007504, -73.06542722376443),
      new google.maps.LatLng(-2.3169760076799024, -73.06616740076053),
      new google.maps.LatLng(-2.311561127133281, -73.07449439196681),
      new google.maps.LatLng(-2.310787572769478, -73.08337651592016),
      new google.maps.LatLng(-2.312914847269936, -73.0874474893988),
      new google.maps.LatLng(-2.3233578311812773, -73.09836510009146),
      new google.maps.LatLng(-2.3264520486364892, -73.11390881700984),
      new google.maps.LatLng(-2.313301624451838, -73.1248264277025),
      new google.maps.LatLng(-2.307886743905217, -73.1227909409632),
      new google.maps.LatLng(-2.300731366040039, -73.11668448074526),
      new google.maps.LatLng(-2.2937693767658116, -73.11446394975692),
      new google.maps.LatLng(-2.282359449899717, -73.12038536572582),
      new google.maps.LatLng(-2.277137957944047, -73.12519651620056),
      new google.maps.LatLng(-2.2711429116245734, -73.1385197021306),
      new google.maps.LatLng(-2.2593462075765776, -73.15202793230966),
      new google.maps.LatLng(-2.248903223665237, -73.1588745695237),
      new google.maps.LatLng(-2.2372999082081915, -73.16461094124358),
      new google.maps.LatLng(-2.2233759296597375, -73.16886695897124),
      new google.maps.LatLng(-2.1966883041085334, -73.17219775545374),
      new google.maps.LatLng(-2.1899197034252573, -73.17071740146152),
      new google.maps.LatLng(-2.186825485970045, -73.16720156072998),
      new google.maps.LatLng(-2.1856651544243406, -73.1588745695237),
      new google.maps.LatLng(-2.187599040333848, -73.14684669333687),
      new google.maps.LatLng(-2.1864387087881436, -73.13907483487768),
      new google.maps.LatLng(-2.183731268514833, -73.13352350740684),
      new google.maps.LatLng(-2.178122999377261, -73.12834226843404),
      new google.maps.LatLng(-2.1676800154659204, -73.12371616220834),
      new google.maps.LatLng(-2.151435373826057, -73.12131058697096),
      new google.maps.LatLng(-2.130356017412425, -73.11427890550789),
      new google.maps.LatLng(-2.1253279140477055, -73.11113315327441),
      new google.maps.LatLng(-2.120106422092035, -73.09799501159341),
      new google.maps.LatLng(-2.1112105469083002, -73.08467182566336),
      new google.maps.LatLng(-2.106762609316433, -73.07578970171001),
      new google.maps.LatLng(-2.1027014489064673, -73.07227386097847),
      new google.maps.LatLng(-2.0924518535860774, -73.06986828574111),
      new google.maps.LatLng(-2.0829758126294906, -73.07060846273723),
      new google.maps.LatLng(-2.081622092492835, -73.07467943621585),
      new google.maps.LatLng(-2.0845229213570966, -73.08781757789684),
      new google.maps.LatLng(-2.080074983765229, -73.08652226815366),
      new google.maps.LatLng(-2.0617030676249075, -73.08652226815366),
      new google.maps.LatLng(-2.056481575669237, -73.09392403811478),
      new google.maps.LatLng(-2.055708021305434, -73.10336129481523),
      new google.maps.LatLng(-2.0452650373940933, -73.09540439210701),
      new google.maps.LatLng(-2.0396567682565214, -73.09355394961672),
      new google.maps.LatLng(-2.0321146132094423, -73.0946642151109),
      new google.maps.LatLng(-2.0247658467533136, -73.10225102932105),
      new google.maps.LatLng(-2.020511297752397, -73.11205837451955),
      new google.maps.LatLng(-2.011808811159613, -73.12131058697096),
      new google.maps.LatLng(-2.0002054957025677, -73.12371616220834),
      new google.maps.LatLng(-1.9961443352926023, -73.1222358082161),
      new google.maps.LatLng(-1.9798996936527389, -73.1235311179593),
      new google.maps.LatLng(-1.9694567097413982, -73.11686952499429),
      new google.maps.LatLng(-1.950698016419175, -73.11316864001373),
      new google.maps.LatLng(-1.9389013123711791, -73.11261350726664),
      new google.maps.LatLng(-1.9269112197322324, -73.11464899400595),
      new google.maps.LatLng(-1.916855013002793, -73.11853492323554),
      new google.maps.LatLng(-1.9064120290914524, -73.1163143922472),
      new google.maps.LatLng(-1.8934549934977518, -73.10743226829385),
      new google.maps.LatLng(-1.8803045693131009, -73.10854253378801),
      new google.maps.LatLng(-1.873149191447923, -73.11168828602149),
      new google.maps.LatLng(-1.8708285283565136, -73.1202003214768),
      new google.maps.LatLng(-1.8725690256750704, -73.12889740118112),
      new google.maps.LatLng(-1.8683144766741542, -73.14148041011505),
      new google.maps.LatLng(-1.8545838867166506, -73.1398150118738),
      new google.maps.LatLng(-1.844721068578162, -73.1398150118738),
      new google.maps.LatLng(-1.8338913074849197, -73.14259067560921),
      new google.maps.LatLng(-1.8304103128478064, -73.14610651634075),
      new google.maps.LatLng(-1.8255755980740376, -73.14481120659755),
      new google.maps.LatLng(-1.811071453752731, -73.14536633934465),
      new google.maps.LatLng(-1.8046896302513562, -73.14684669333687),
      new google.maps.LatLng(-1.8008218584323408, -73.1503625340684),
      new google.maps.LatLng(-1.8019821899780455, -73.15776430402954),
      new google.maps.LatLng(-1.8019821899780455, -73.16979218021638),
      new google.maps.LatLng(-1.8075904591156173, -73.17941448116585),
      new google.maps.LatLng(-1.8083640134794203, -73.18441067588961),
      new google.maps.LatLng(-1.8058499617970605, -73.18792651662115),
      new google.maps.LatLng(-1.791345817475754, -73.19273766709588),
      new google.maps.LatLng(-1.7830301080648718, -73.19717872907256),
      new google.maps.LatLng(-1.7665920778340576, -73.20384032203758),
      new google.maps.LatLng(-1.7532482650584558, -73.2117972247458),
      new google.maps.LatLng(-1.74918710464849, -73.21660837522053),
      new google.maps.LatLng(-1.748413550284687, -73.22141952569527),
      new google.maps.LatLng(-1.754601985195111, -73.23030164964862),
      new google.maps.LatLng(-1.7667854664250084, -73.23178200364084),
      new google.maps.LatLng(-1.7826433308829701, -73.2295614726525),
      new google.maps.LatLng(-1.788831765793394, -73.2295614726525),
      new google.maps.LatLng(-1.7988879725228335, -73.23326235763307),
      new google.maps.LatLng(-1.8029491329327993, -73.23788846385878),
      new google.maps.LatLng(-1.8058499617970605, -73.2471406763102),
      new google.maps.LatLng(-1.8035292987056515, -73.25620784451257),
      new google.maps.LatLng(-1.7967606980223754, -73.25657793301063),
      new google.maps.LatLng(-1.7926995376124095, -73.24732572055922),
      new google.maps.LatLng(-1.7886383772024437, -73.24399492407672),
      new google.maps.LatLng(-1.7845772167924778, -73.2445500568238),
      new google.maps.LatLng(-1.780516056382512, -73.24936120729853),
      new google.maps.LatLng(-1.7803226677915611, -73.25509757901841),
      new google.maps.LatLng(-1.782063165110118, -73.26823572069942),
      new google.maps.LatLng(-1.7818697765191671, -73.27452722516638),
      new google.maps.LatLng(-1.7760681187906444, -73.2871102341003),
      new google.maps.LatLng(-1.7770350617453983, -73.29821288904199),
      new google.maps.LatLng(-1.7801292792006103, -73.30357917226382),
      new google.maps.LatLng(-1.787478045656739, -73.3087604112366),
      new google.maps.LatLng(-1.8035292987056515, -73.30839032273855),
      new google.maps.LatLng(-1.8124251738893862, -73.30376421651285),
      new google.maps.LatLng(-1.8186136087998102, -73.30561465900313),
      new google.maps.LatLng(-1.8259623752559389, -73.3041343050109),
      new google.maps.LatLng(-1.8269293182106927, -73.31449678295648),
      new google.maps.LatLng(-1.8228681578007269, -73.32263872991373),
      new google.maps.LatLng(-1.8081706248884695, -73.32967041137681),
      new google.maps.LatLng(-1.8014020242051934, -73.33448156185155),
      new google.maps.LatLng(-1.7959871436585724, -73.35132058851312),
      new google.maps.LatLng(-1.7899920973390988, -73.36186811070772),
      new google.maps.LatLng(-1.782836719473921, -73.37019510191399),
      new google.maps.LatLng(-1.7774218389273, -73.37963235861444),
      new google.maps.LatLng(-1.7745210100630384, -73.39166023480128),
      new google.maps.LatLng(-1.775874730199694, -73.3970265180231),
      new google.maps.LatLng(-1.785930936929133, -73.40960952695703),
      new google.maps.LatLng(-1.789798708748148, -73.42459811112832),
      new google.maps.LatLng(-1.789798708748148, -73.42940926160304),
      new google.maps.LatLng(-1.7845772167924778, -73.4336652793307),
      new google.maps.LatLng(-1.7770350617453983, -73.43533067757195),
      new google.maps.LatLng(-1.7654317462883533, -73.43514563332292),
      new google.maps.LatLng(-1.7604036429236336, -73.43718112006223),
      new google.maps.LatLng(-1.747446607329933, -73.45087439449033),
      new google.maps.LatLng(-1.7371970120095432, -73.4579060759534),
      new google.maps.LatLng(-1.7344895717362325, -73.4625321821791),
      new google.maps.LatLng(-1.7346829603271834, -73.46956386364218),
      new google.maps.LatLng(-1.7323622972357744, -73.47863103184457),
      new google.maps.LatLng(-1.7323622972357744, -73.48732811154889),
      new google.maps.LatLng(-1.7288813025986607, -73.50139147447504),
      new google.maps.LatLng(-1.7209523703696799, -73.51045864267743),
      new google.maps.LatLng(-1.7149573240502063, -73.52119120912107),
      new google.maps.LatLng(-1.7058680602755212, -73.53358917380596),
      new google.maps.LatLng(-1.698712682410343, -73.53895545702778),
      new google.maps.LatLng(-1.6867225897713962, -73.53895545702778),
      new google.maps.LatLng(-1.6820812635885782, -73.53543961629624),
      new google.maps.LatLng(-1.6675771192672717, -73.51860058963467),
      new google.maps.LatLng(-1.6484316487631472, -73.50860820018715),
      new google.maps.LatLng(-1.6372151104880035, -73.50934837718326),
      new google.maps.LatLng(-1.6306398983956778, -73.50805306744006),
      new google.maps.LatLng(-1.6234845205305, -73.50361200546338),
      new google.maps.LatLng(-1.611881205073455, -73.49380466026489),
      new google.maps.LatLng(-1.5979572265250006, -73.47270961587566),
      new google.maps.LatLng(-1.588094408386512, -73.4630873149262),
      new google.maps.LatLng(-1.57726464729327, -73.45735094320632),
      new google.maps.LatLng(-1.5693357150642888, -73.45827616445146),
      new google.maps.LatLng(-1.5675952177457324, -73.4664181114087),
      new google.maps.LatLng(-1.5732034868833042, -73.47363483712081),
      new google.maps.LatLng(-1.5747505956109102, -73.47863103184457),
      new google.maps.LatLng(-1.5730100982923534, -73.48732811154889),
      new google.maps.LatLng(-1.5586993425619977, -73.48159173982901),
      new google.maps.LatLng(-1.552124130469672, -73.48233191682513),
      new google.maps.LatLng(-1.5486431358325583, -73.48492253631153),
      new google.maps.LatLng(-1.529884442510335, -73.47863103184457),
      new google.maps.LatLng(-1.5225356760542068, -73.47863103184457),
      new google.maps.LatLng(-1.5188612928261422, -73.48177678407805),
      new google.maps.LatLng(-1.517700961280438, -73.48769820004695),
      new google.maps.LatLng(-1.5105455834152601, -73.49491492575906),
      new google.maps.LatLng(-1.5059042572324417, -73.49343457176683),
      new google.maps.LatLng(-1.5022298740043776, -73.48899350979015),
      new google.maps.LatLng(-1.4943009417753967, -73.48566271330765),
      new google.maps.LatLng(-1.4873389525011693, -73.48751315579793),
      new google.maps.LatLng(-1.48231084913645, -73.49084395228043),
      new google.maps.LatLng(-1.4836645692731052, -73.5017615629731),
      new google.maps.LatLng(-1.48231084913645, -73.5108287311755),
      new google.maps.LatLng(-1.4797967974540902, -73.51563988165023),
      new google.maps.LatLng(-1.4654860417237345, -73.52840793483317),
      new google.maps.LatLng(-1.4565901665399998, -73.53062846582151),
      new google.maps.LatLng(-1.4422794108096437, -73.53081351007054),
      new google.maps.LatLng(-1.4358975873082689, -73.5324789083118),
      new google.maps.LatLng(-1.4306760953525988, -73.54025076677098),
      new google.maps.LatLng(-1.4291289866249928, -73.5520935987088),
      new google.maps.LatLng(-1.4165587282131935, -73.55523935094227),
      new google.maps.LatLng(-1.4136578993489324, -73.55264873145588),
      new google.maps.LatLng(-1.4024413610737887, -73.55042820046754),
      new google.maps.LatLng(-1.393545485890054, -73.55338890845199),
      new google.maps.LatLng(-1.3792347301596983, -73.56079067841313),
      new google.maps.LatLng(-1.369952077794062, -73.56819244837425),
      new google.maps.LatLng(-1.360476036837475, -73.57781474932372),
      new google.maps.LatLng(-1.3411371777423997, -73.57948014756498),
      new google.maps.LatLng(-1.3359156857867296, -73.57781474932372),
      new google.maps.LatLng(-1.326246256239192, -73.56874758112134),
      new google.maps.LatLng(-1.3225718730111273, -73.56893262537037),
      new google.maps.LatLng(-1.319090878374014, -73.57485404133928),
      new google.maps.LatLng(-1.321604930056374, -73.58040536881012),
      new google.maps.LatLng(-1.327793364966798, -73.58891740426542),
      new google.maps.LatLng(-1.3289536965125022, -73.59594908572849),
      new google.maps.LatLng(-1.322378484420177, -73.60742182916825),
      new google.maps.LatLng(-1.318317324010211, -73.61130775839784),
      new google.maps.LatLng(-1.3069073971441165, -73.61482359912938),
      new google.maps.LatLng(-1.287761926639992, -73.6175992628648),
      new google.maps.LatLng(-1.2720974507729808, -73.61778430711384),
      new google.maps.LatLng(-1.262621409816394, -73.61944970535508),
      new google.maps.LatLng(-1.2604941353159358, -73.62222536909051),
      new google.maps.LatLng(-1.2668759588173106, -73.62944209480261),
      new google.maps.LatLng(-1.268809844726818, -73.63906439575209),
      new google.maps.LatLng(-1.2674561245901628, -73.64535590021904),
      new google.maps.LatLng(-1.2635883527711478, -73.65275767018018),
      new google.maps.LatLng(-1.2477304883131857, -73.66515563486507),
      new google.maps.LatLng(-1.2407684990389587, -73.67551811281065),
      new google.maps.LatLng(-1.2396081674932544, -73.68291988277178),
      new google.maps.LatLng(-1.2446362708579737, -73.69069174123098),
      new google.maps.LatLng(-1.2527585916779054, -73.69994395368239),
      new google.maps.LatLng(-1.2537255346326592, -73.70790085639061),
      new google.maps.LatLng(-1.252371814496004, -73.71659793609493),
      new google.maps.LatLng(-1.2485040426769887, -73.7288108565308),
      new google.maps.LatLng(-1.2399949446751557, -73.73898829022735),
      new google.maps.LatLng(-1.2320660124461749, -73.74157890971375),
      new google.maps.LatLng(-1.2231701372624402, -73.74139386546472),
      new google.maps.LatLng(-1.2160147593972623, -73.74379944070209),
      new google.maps.LatLng(-1.2082792157592324, -73.74435457344917),
      new google.maps.LatLng(-1.2009304493031037, -73.74305926370597),
      new google.maps.LatLng(-1.199770117757399, -73.74676014868655),
      new google.maps.LatLng(-1.205958552667823, -73.75342174165156),
      new google.maps.LatLng(-1.2204626969891295, -73.75268156465545),
      new google.maps.LatLng(-1.2351602299013869, -73.7530516531535),
      new google.maps.LatLng(-1.2502445399955455, -73.7602683788656),
      new google.maps.LatLng(-1.2573999178607234, -73.762858998352),
      new google.maps.LatLng(-1.2606875239068862, -73.7667449275816),
      new google.maps.LatLng(-1.260107358134034, -73.77914289226649),
      new google.maps.LatLng(-1.256239586315019, -73.78413908699025),
      new google.maps.LatLng(-1.2481172654950874, -73.78099333475677),
      new google.maps.LatLng(-1.2415420534027617, -73.78432413123929),
      new google.maps.LatLng(-1.2399949446751557, -73.7896904144611),
      new google.maps.LatLng(-1.2473437111312844, -73.79653705167514),
      new google.maps.LatLng(-1.2496643742226934, -73.80134820214988),
      new google.maps.LatLng(-1.2490842084498412, -73.81152563584644),
      new google.maps.LatLng(-1.2401883332661066, -73.82022271555077),
      new google.maps.LatLng(-1.2370941158108946, -73.82466377752745),
      new google.maps.LatLng(-1.2359337842651898, -73.84224298118514),
      new google.maps.LatLng(-1.2328395668099779, -73.85538112286613),
      new google.maps.LatLng(-1.2289717949909629, -73.86296793707629),
      new google.maps.LatLng(-1.2208494741710312, -73.86814917604909),
      new google.maps.LatLng(-1.2105998788506414, -73.86777908755103),
      new google.maps.LatLng(-1.200350283530251, -73.8701846627884),
      new google.maps.LatLng(-1.194355237210778, -73.87425563626702),
      new google.maps.LatLng(-1.1783039841618654, -73.89016944168345),
      new google.maps.LatLng(-1.1692147203871799, -73.89590581340333),
      new google.maps.LatLng(-1.164380005613411, -73.89720112314653),
      new google.maps.LatLng(-1.1498758612921045, -73.89701607889751),
      new google.maps.LatLng(-1.1409799861083698, -73.90034687538001),
      new google.maps.LatLng(-1.1295700592422753, -73.90312253911543),
      new google.maps.LatLng(-1.1206741840585406, -73.90904395508434),
      new google.maps.LatLng(-1.1181601323761812, -73.91441023830616),
      new google.maps.LatLng(-1.1231882357409004, -73.92551289324786),
      new google.maps.LatLng(-1.1303436136060783, -73.93198944196385),
      new google.maps.LatLng(-1.1324708881065368, -73.93606041544247),
      new google.maps.LatLng(-1.133824608243192, -73.94864342437639),
      new google.maps.LatLng(-1.1305370021970291, -73.96011616781614),
      new google.maps.LatLng(-1.1282163391056201, -73.96215165455546),
      new google.maps.LatLng(-1.1166130236485752, -73.9571554598317),
      new google.maps.LatLng(-1.1081039256467418, -73.96011616781614),
      new google.maps.LatLng(-1.101722102145367, -73.95363961910016),
      new google.maps.LatLng(-1.093213004143534, -73.95271439785502),
      new google.maps.LatLng(-1.0847039061417005, -73.95808068107684),
      new google.maps.LatLng(-1.0845105175507497, -73.96622262803407),
      new google.maps.LatLng(-1.089925398097371, -73.97269917675007),
      new google.maps.LatLng(-1.1026890451001208, -73.97658510597967),
      new google.maps.LatLng(-1.1081039256467418, -73.98528218568399),
      new google.maps.LatLng(-1.104042765236776, -73.99731006187082),
      new google.maps.LatLng(-1.0974675531444502, -74.00619218582419),
      new google.maps.LatLng(-1.0897320095064202, -74.01229864604211),
      new google.maps.LatLng(-1.0783220826403257, -74.01747988501491),
      new google.maps.LatLng(-1.0620774410004623, -74.01914528325617),
      new google.maps.LatLng(-1.0506675141343682, -74.02321625673478),
      new google.maps.LatLng(-1.0369369241768647, -74.02173590274256),
      new google.maps.LatLng(-1.0230129456284103, -74.01507430977755),
      new google.maps.LatLng(-1.0145038476265773, -74.01488926552851),
      new google.maps.LatLng(-1.0079286355342516, -74.01840510626005),
      new google.maps.LatLng(-1.0032873093514332, -74.02506669922506),
      new google.maps.LatLng(-1.005221195260941, -74.03172829219008),
      new google.maps.LatLng(-1.0100559100347097, -74.03579926566871),
      new google.maps.LatLng(-1.0222393912646073, -74.037649708159),
      new google.maps.LatLng(-1.0315220436302432, -74.03616935416676),
      new google.maps.LatLng(-1.0493137939977126, -74.05115793833805),
      new google.maps.LatLng(-1.0533749544076785, -74.0605951950385),
      new google.maps.LatLng(-1.0512476799072203, -74.07206793847826),
      new google.maps.LatLng(-1.0448658564058455, -74.08724156689857),
      new google.maps.LatLng(-1.04351213626919, -74.0933480271165),
      new google.maps.LatLng(-1.0369369241768647, -74.10796652278974),
      new google.maps.LatLng(-1.03577659263116, -74.11536829275087),
      new google.maps.LatLng(-1.0307484892664402, -74.12628590344353),
      new google.maps.LatLng(-1.0293947691297851, -74.1333175849066),
      new google.maps.LatLng(-1.026493940265524, -74.13868386812842),
      new google.maps.LatLng(-1.0160509563541833, -74.15034165581721),
      new google.maps.LatLng(-1.0077352469433007, -74.16551528423753),
      new google.maps.LatLng(-1.005801361033793, -74.17532262943602),
      new google.maps.LatLng(-1.0087021898980546, -74.1836496206423),
      new google.maps.LatLng(-1.0085088013071037, -74.19290183309371),
      new google.maps.LatLng(-1.0104426872166115, -74.20492970928055),
      new google.maps.LatLng(-1.0092823556709067, -74.21917811645574),
      new google.maps.LatLng(-1.0054145838518918, -74.22787519616006),
      new google.maps.LatLng(-0.9887831650301271, -74.25378139102402),
      new google.maps.LatLng(-0.9796939012554415, -74.27099050618365),
      new google.maps.LatLng(-0.9754393522545248, -74.26580926721086),
      new google.maps.LatLng(-0.9700244717079038, -74.26358873622252),
      new google.maps.LatLng(-0.9537798300680405, -74.27080546193461),
      new google.maps.LatLng(-0.9458508978390596, -74.2641438689696),
      new google.maps.LatLng(-0.9408227944743399, -74.2641438689696),
      new google.maps.LatLng(-0.929219479017295, -74.27339608142101),
      new google.maps.LatLng(-0.9239979870616244, -74.27561661240935),
      new google.maps.LatLng(-0.9129748373774316, -74.27561661240935),
      new google.maps.LatLng(-0.9058194595122537, -74.27857732039381),
      new google.maps.LatLng(-0.8982773044651742, -74.28542395760785),
      new google.maps.LatLng(-0.8940227554642575, -74.2948612143083),
      new google.maps.LatLng(-0.8922822581457011, -74.30374333826165),
      new google.maps.LatLng(-0.8942161440552083, -74.31096006397375),
      new google.maps.LatLng(-0.8932492011004545, -74.31503103745237),
      new google.maps.LatLng(-0.8868673775990796, -74.3216926304174),
      new google.maps.LatLng(-0.875650839323936, -74.32206271891545),
      new google.maps.LatLng(-0.868688850049709, -74.31799174543683),
      new google.maps.LatLng(-0.865594632594497, -74.31373572770917),
      new google.maps.LatLng(-0.8636607466849893, -74.30577882500097),
      new google.maps.LatLng(-0.864047523866891, -74.3000424532811),
      new google.maps.LatLng(-0.8669483527311521, -74.29393599306316),
      new google.maps.LatLng(-0.8793252225520005, -74.279872630137),
      new google.maps.LatLng(-0.8777781138243945, -74.27191572742879),
      new google.maps.LatLng(-0.8684954614587581, -74.26784475395017),
      new google.maps.LatLng(-0.8565053688198114, -74.27265590442491),
      new google.maps.LatLng(-0.8493499909546336, -74.27265590442491),
      new google.maps.LatLng(-0.8423880016804066, -74.27043537343657),
      new google.maps.LatLng(-0.8323317949509672, -74.26192333798126),
      new google.maps.LatLng(-0.8224689768124787, -74.24878519630026),
      new google.maps.LatLng(-0.8170540962658577, -74.24748988655706),
      new google.maps.LatLng(-0.8141532674015965, -74.2510057272886),
      new google.maps.LatLng(-0.8137664902196948, -74.25655705475944),
      new google.maps.LatLng(-0.8251764170857894, -74.27672687790353),
      new google.maps.LatLng(-0.8255631942676911, -74.2824632496234),
      new google.maps.LatLng(-0.8209218680848727, -74.28930988683744),
      new google.maps.LatLng(-0.8147334331744487, -74.2935659045651),
      new google.maps.LatLng(-0.805064003626911, -74.29005006383356),
      new google.maps.LatLng(-0.795007796897472, -74.29079024082968),
      new google.maps.LatLng(-0.7864986988956386, -74.28468378061174),
      new google.maps.LatLng(-0.7787631552576086, -74.28468378061174),
      new google.maps.LatLng(-0.7733482747109872, -74.28949493108648),
      new google.maps.LatLng(-0.7737350518928889, -74.29726678954566),
      new google.maps.LatLng(-0.7777962123028548, -74.30096767452623),
      new google.maps.LatLng(-0.7874656418503925, -74.3046685595068),
      new google.maps.LatLng(-0.7921069680332105, -74.31170024096987),
      new google.maps.LatLng(-0.7917201908513092, -74.3165113914446),
      new google.maps.LatLng(-0.7774094351209531, -74.31503103745237),
      new google.maps.LatLng(-0.7729614975290859, -74.31873192243295),
      new google.maps.LatLng(-0.7646457881182034, -74.31503103745237),
      new google.maps.LatLng(-0.7571036330711243, -74.31392077195821),
      new google.maps.LatLng(-0.7520755297064046, -74.31762165693877),
      new google.maps.LatLng(-0.7514953639335524, -74.32705891363922),
      new google.maps.LatLng(-0.7572970216620747, -74.34056714381828),
      new google.maps.LatLng(-0.7559433015254196, -74.35018944476775),
      new google.maps.LatLng(-0.7435664317045716, -74.36203227670556),
      new google.maps.LatLng(-0.7364110538393933, -74.37220971040212),
      new google.maps.LatLng(-0.7304160075199202, -74.37257979890018),
      new google.maps.LatLng(-0.7257746813371022, -74.35407537399735),
      new google.maps.LatLng(-0.7153316974257615, -74.34500820579495),
      new google.maps.LatLng(-0.709336651106288, -74.34204749781051),
      new google.maps.LatLng(-0.7006341645135041, -74.3413073208144),
      new google.maps.LatLng(-0.6925118436935724, -74.34408298454981),
      new google.maps.LatLng(-0.6859366316012467, -74.34241758630856),
      new google.maps.LatLng(-0.6780076993722659, -74.34241758630856),
      new google.maps.LatLng(-0.6720126530527928, -74.3459334270401),
      new google.maps.LatLng(-0.6640837208238115, -74.36480794044098),
      new google.maps.LatLng(-0.6598291718228952, -74.37128448915698),
      new google.maps.LatLng(-0.6439713073649331, -74.36443785194292),
      new google.maps.LatLng(-0.639523369773066, -74.36388271919584),
      new google.maps.LatLng(-0.6271464999522176, -74.36850882542154),
      new google.maps.LatLng(-0.6194109563141876, -74.36776864842544),
      new google.maps.LatLng(-0.6116754126761577, -74.3707293564099),
      new google.maps.LatLng(-0.6023927603105212, -74.38238714409867),
      new google.maps.LatLng(-0.5921431649901314, -74.3897889140598),
      new google.maps.LatLng(-0.5809266267149877, -74.38867864856563),
      new google.maps.LatLng(-0.4304703029553014, -73.88369289296746),
      new google.maps.LatLng(-0.42737608550008943, -73.8701846627884),
      new google.maps.LatLng(-0.4370455150476271, -73.86019227334087),
      new google.maps.LatLng(-0.4424603955942481, -73.84760926440696),
      new google.maps.LatLng(-0.4436207271399528, -73.82799457400995),
      new google.maps.LatLng(-0.44071989827569125, -73.81078545885032),
      new google.maps.LatLng(-0.4351116291381194, -73.79579687467904),
      new google.maps.LatLng(-0.4293099714095967, -73.79043059145721),
      new google.maps.LatLng(-0.418866987498256, -73.78709979497471),
      new google.maps.LatLng(-0.4132587183606842, -73.77377660904467),
      new google.maps.LatLng(-0.4109380552692752, -73.76396926384618),
      new google.maps.LatLng(-0.41035788949642305, -73.75064607791613),
      new google.maps.LatLng(-0.4190603760892069, -73.73972846722347),
      new google.maps.LatLng(-0.4202207076349116, -73.7334369627565),
      new google.maps.LatLng(-0.43317774322861213, -73.72418475030508),
      new google.maps.LatLng(-0.4401397325028391, -73.71141669712213),
      new google.maps.LatLng(-0.4440075043218541, -73.70234952891975),
      new google.maps.LatLng(-0.44188022982139596, -73.68847121024264),
      new google.maps.LatLng(-0.4447810586856571, -73.67810873229705),
      new google.maps.LatLng(-0.4529033795055888, -73.67810873229705),
      new google.maps.LatLng(-0.46276619764407734, -73.68032926328539),
      new google.maps.LatLng(-0.4759166218287283, -73.67921899779122),
      new google.maps.LatLng(-0.4681810781906983, -73.64461572322293),
      new google.maps.LatLng(-0.4668273580540432, -73.63425324527735),
      new google.maps.LatLng(-0.46934140973640304, -73.62851687355747),
      new google.maps.LatLng(-0.47804389632918687, -73.62444590007885),
      new google.maps.LatLng(-0.4940951493780994, -73.62167023634342),
      new google.maps.LatLng(-0.5004769728794742, -73.6168590858687),
      new google.maps.LatLng(-0.5103397910179628, -73.60594147517602),
      new google.maps.LatLng(-0.5190422776107466, -73.58817722726931),
      new google.maps.LatLng(-0.5277447642035304, -73.59613412997753),
      new google.maps.LatLng(-0.544569571616246, -73.59613412997753),
      new google.maps.LatLng(-0.5439894058433938, -73.58114554580624),
      new google.maps.LatLng(-0.5319993132044472, -73.57133820060774),
      new google.maps.LatLng(-0.5511447837085717, -73.54765253673212),
      new google.maps.LatLng(-0.5550125555275867, -73.53858536852974),
      new google.maps.LatLng(-0.5557861098913897, -73.53284899680986),
      new google.maps.LatLng(-0.5559794984823405, -73.50213165147116),
      new google.maps.LatLng(-0.5553993327094884, -73.48880846554113),
      new google.maps.LatLng(-0.5519183380723747, -73.4762254566072),
      new google.maps.LatLng(-0.5497910635719165, -73.46234713793008),
      new google.maps.LatLng(-0.546890234707655, -73.45439023522187),
      new google.maps.LatLng(-0.5555927213004388, -73.43588581031904),
      new google.maps.LatLng(-0.5573332186189957, -73.41627111992204),
      new google.maps.LatLng(-0.5447629602071968, -73.38758926132266),
      new google.maps.LatLng(-0.5447629602071968, -73.38518368608528),
      new google.maps.LatLng(-0.552305115254276, -73.36612412843537),
      new google.maps.LatLng(-0.5675828139393855, -73.33263111936125),
      new google.maps.LatLng(-0.5710638085764992, -73.32060324317442),
      new google.maps.LatLng(-0.572804305895056, -73.30746510149342),
      new google.maps.LatLng(-0.5822803468516429, -73.29414191556337),
      new google.maps.LatLng(-0.5989117656734075, -73.28433457036488),
      new google.maps.LatLng(-0.6070340864933392, -73.27785802164888),
      new google.maps.LatLng(-0.6139960757675667, -73.27563749066054),
      new google.maps.LatLng(-0.6250192254517595, -73.2543574020223),
      new google.maps.LatLng(-0.637202706681657, -73.2530620922791),
      new google.maps.LatLng(-0.6453250275015887, -73.24047908334518),
      new google.maps.LatLng(-0.6528671825486678, -73.23122687089376),
      new google.maps.LatLng(-0.6575085087314863, -73.22308492393651),
      new google.maps.LatLng(-0.6569283429586337, -73.20754120701814),
      new google.maps.LatLng(-0.649579576502505, -73.19773386181964),
      new google.maps.LatLng(-0.644938250319687, -73.18903678211532),
      new google.maps.LatLng(-0.6358489865450019, -73.16387076424746),
      new google.maps.LatLng(-0.634882043590248, -73.14425607385047),
      new google.maps.LatLng(-0.6389432040002139, -73.13500386139906),
      new google.maps.LatLng(-0.644164695955884, -73.12686191444182),
      new google.maps.LatLng(-0.6482258563658498, -73.11705456924332),
      new google.maps.LatLng(-0.6482258563658498, -73.10502669305647),
      new google.maps.LatLng(-0.6424241986373271, -73.0933689053677),
      new google.maps.LatLng(-0.6246324482698578, -73.09225863987353),
      new google.maps.LatLng(-0.6014258173557674, -73.09225863987353),
      new google.maps.LatLng(-0.5952373824453434, -73.08837271064394),
      new google.maps.LatLng(-0.5842142327611506, -73.07856536544543),
      new google.maps.LatLng(-0.5755117461683668, -73.07449439196681),
      new google.maps.LatLng(-0.5588803273466016, -73.06931315299403),
      new google.maps.LatLng(-0.5536588353909315, -73.0606160732897),
      new google.maps.LatLng(-0.5538522239818819, -73.03119403769419),
      new google.maps.LatLng(-0.5555927213004388, -73.01565032077582),
      new google.maps.LatLng(-0.5642952078932231, -72.99844120561619),
      new google.maps.LatLng(-0.5793795179873817, -72.9873385506745),
      new google.maps.LatLng(-0.5863415072616087, -72.97697607272892),
      new google.maps.LatLng(-0.6165101274499265, -72.96328279830082),
      new google.maps.LatLng(-0.6321746033169373, -72.957916515079),
      new google.maps.LatLng(-0.6459051932744408, -72.95106987786495),
      new google.maps.LatLng(-0.6459051932744408, -72.93552616094658),
      new google.maps.LatLng(-0.6443580845468349, -72.92275810776363),
      new google.maps.LatLng(-0.6443580845468349, -72.91350589531221),
      new google.maps.LatLng(-0.6484192449568007, -72.89851731114092),
      new google.maps.LatLng(-0.648032467774899, -72.88241846147547),
      new google.maps.LatLng(-0.6416506442735241, -72.85947297459596),
      new google.maps.LatLng(-0.637202706681657, -72.85133102763871),
      new google.maps.LatLng(-0.6302407174074296, -72.83412191247908),
      new google.maps.LatLng(-0.6194109563141876, -72.82301925753738),
      new google.maps.LatLng(-0.6182506247684829, -72.8178380185646),
      new google.maps.LatLng(-0.6072274750842901, -72.80988111585637),
      new google.maps.LatLng(-0.6010390401738661, -72.80358961138941),
      new google.maps.LatLng(-0.5900158904896733, -72.79544766443217),
      new google.maps.LatLng(-0.5849877871249536, -72.78397492099242),
      new google.maps.LatLng(-0.5849877871249536, -72.77176200055655),
      new google.maps.LatLng(-0.5919497763991806, -72.76713589433085),
      new google.maps.LatLng(-0.5992985428553093, -72.76491536334251),
      new google.maps.LatLng(-0.6168969046318278, -72.73197748701547),
      new google.maps.LatLng(-0.6285002200888732, -72.72513084980143),
      new google.maps.LatLng(-0.6337217120445433, -72.71698890284418),
      new google.maps.LatLng(-0.6424241986373271, -72.71014226563014),
      new google.maps.LatLng(-0.6911581235569173, -72.65129819443915),
      new google.maps.LatLng(-0.6975399470582921, -72.6431562474819),
      new google.maps.LatLng(-0.7178457491081214, -72.61262394639223),
      new google.maps.LatLng(-0.7294490645651663, -72.5907887250069),
      new google.maps.LatLng(-0.7300292303380185, -72.58042624706131),
      new google.maps.LatLng(-0.7221002981090376, -72.5711740346099),
      new google.maps.LatLng(-0.7133978115162538, -72.56710306113128),
      new google.maps.LatLng(-0.700827553104455, -72.55563031769152),
      new google.maps.LatLng(-0.6880639061017053, -72.54064173352023),
      new google.maps.LatLng(-0.680134973872724, -72.53435022905327),
      new google.maps.LatLng(-0.6673713268699744, -72.52620828209604),
      new google.maps.LatLng(-0.6530605711396187, -72.51177483067183),
      new google.maps.LatLng(-0.6385564268183122, -72.50437306071069),
      new google.maps.LatLng(-0.631787826135036, -72.49512084825928),
      new google.maps.LatLng(-0.6225051737693996, -72.49049474203358),
      new google.maps.LatLng(-0.6167035160408769, -72.48364810481952),
      new google.maps.LatLng(-0.6116754126761577, -72.48068739683507),
      new google.maps.LatLng(-0.5902092790806237, -72.45182049398666),
      new google.maps.LatLng(-0.5863415072616087, -72.44386359127844),
      new google.maps.LatLng(-0.5863415072616087, -72.43294598058577),
      new google.maps.LatLng(-0.6139960757675667, -72.43109553809549),
      new google.maps.LatLng(-0.6186374019503846, -72.42702456461687),
      new google.maps.LatLng(-0.6215382308146458, -72.42073306014991),
      new google.maps.LatLng(-0.6343018778173954, -72.4114808476985),
      new google.maps.LatLng(-0.6424241986373271, -72.4016735025),
      new google.maps.LatLng(-0.6476456905929977, -72.39945297151166),
      new google.maps.LatLng(-0.6553812342310277, -72.38724005107579),
      new google.maps.LatLng(-0.6553812342310277, -72.38150367935592),
      new google.maps.LatLng(-0.6617630577324025, -72.37391686514576),
      new google.maps.LatLng(-0.6687250470066299, -72.36077872346475),
      new google.maps.LatLng(-0.6722060416437432, -72.33598279409496),
      new google.maps.LatLng(-0.6613762805505012, -72.30952146648391),
      new google.maps.LatLng(-0.6540275140943725, -72.28935164333984),
      new google.maps.LatLng(-0.6165101274499265, -72.2610398732385),
      new google.maps.LatLng(-0.5923365535810823, -72.24956712979875),
      new google.maps.LatLng(-0.5726109173041052, -72.24494102357305),
      new google.maps.LatLng(-0.5501778407538178, -72.23753925361191),
      new google.maps.LatLng(-0.5277447642035304, -72.22773190841342),
      new google.maps.LatLng(-0.4977695326061635, -72.21218819149504),
      new google.maps.LatLng(-0.4544504882331948, -72.20053040380627),
      new google.maps.LatLng(-0.4401397325028391, -72.19201836835096),
      new google.maps.LatLng(-0.41422566131543803, -72.16315146550255),
      new google.maps.LatLng(-0.4020421800855405, -72.15056845656864),
      new google.maps.LatLng(-0.39179258476515066, -72.13780040338568),
      new google.maps.LatLng(-0.3894719216737417, -72.12466226170467),
      new google.maps.LatLng(-0.37922232635335185, -72.11078394302754),
      new google.maps.LatLng(-0.3596900786673256, -72.07618066845926),
      new google.maps.LatLng(-0.3492470947559849, -72.0732199604748),
      new google.maps.LatLng(-0.3389974994355951, -72.07562553571218),
      new google.maps.LatLng(-0.32565368665999284, -72.07618066845926),
      new google.maps.LatLng(-0.3082487134744252, -72.0698891639923),
      new google.maps.LatLng(-0.29741895238118277, -72.05786128780547),
      new google.maps.LatLng(-0.2846553053784331, -72.04675863286377),
      new google.maps.LatLng(-0.28059414496846724, -72.02880934070802),
      new google.maps.LatLng(-0.28020736778656596, -72.00826942906588),
      new google.maps.LatLng(-0.2885230771974485, -71.96441394204618),
      new google.maps.LatLng(-0.2925842376074139, -71.9488702251278),
      new google.maps.LatLng(-0.29374456915311864, -71.9344367737036),
      new google.maps.LatLng(-0.2997396154725922, -71.91704261429494),
      new google.maps.LatLng(-0.30612143897396704, -71.89002615393682),
      new google.maps.LatLng(-0.30109333560924734, -71.85597801211561),
      new google.maps.LatLng(-0.2931644033802665, -71.83284748098708),
      new google.maps.LatLng(-0.276339595967551, -71.8139729675862),
      new google.maps.LatLng(-0.26260900601004744, -71.80138995865227),
      new google.maps.LatLng(-0.18196596358358352, -71.77307818855094),
      new google.maps.LatLng(-0.159919664215197, -71.76216057785828),
      new google.maps.LatLng(-0.15315106353192043, -71.75457376364812),
      new google.maps.LatLng(-0.12723699234452024, -71.73273854226278),
      new google.maps.LatLng(-0.1127328480232137, -71.71034818813035),
      new google.maps.LatLng(-0.0920402687914823, -71.69073349773336),
      new google.maps.LatLng(-0.07772951306112663, -71.66427217012232),
      new google.maps.LatLng(-0.06457908887647612, -71.64465747972532),
      new google.maps.LatLng(-0.05142866469182472, -71.62781845306375),
      new google.maps.LatLng(-0.032283194187700204, -71.62263721409096),
      new google.maps.LatLng(-0.012750946501673965, -71.61171960339829),
      new google.maps.LatLng(0.010262295821465983, -71.57952190406736),
      new google.maps.LatLng(0.021285445505658807, -71.57137995711012),
      new google.maps.LatLng(0.024573051551821656, -71.56342305440191),
      new google.maps.LatLng(0.036756532781718754, -71.55232039946021),
      new google.maps.LatLng(0.047006128102109024, -71.54140278876754),
      new google.maps.LatLng(0.05686894624059757, -71.53270570906321),
      new google.maps.LatLng(0.07524086238091954, -71.52123296562345),
      new google.maps.LatLng(0.0901317838841269, -71.50809482394246),
      new google.maps.LatLng(0.0976739389312069, -71.50457898321092),
      new google.maps.LatLng(0.10811692284254715, -71.49717721324978),
      new google.maps.LatLng(0.10753675706969457, -71.49310623977117),
      new google.maps.LatLng(0.1179797409810357, -71.48607455830809),
      new google.maps.LatLng(0.12068718125434597, -71.46664491216012),
      new google.maps.LatLng(0.11778635239008484, -71.45091615099271),
      new google.maps.LatLng(0.11759296379913398, -71.43666774381754),
      new google.maps.LatLng(0.12919627925617938, -71.42963606235446),
      new google.maps.LatLng(0.14640786385079618, -71.42963606235446),
      new google.maps.LatLng(0.15917151085354586, -71.42741553136612),
      new google.maps.LatLng(0.16033184239925102, -71.42093898265014),
      new google.maps.LatLng(0.15859134508069417, -71.41242694719483),
      new google.maps.LatLng(0.15220952157931933, -71.41113163745163),
      new google.maps.LatLng(0.1301632222109328, -71.40132429225314),
      new google.maps.LatLng(0.12842272489237594, -71.39614305328034),
      new google.maps.LatLng(0.11855990675388828, -71.38633570808184),
      new google.maps.LatLng(0.11159791747966086, -71.37615827438529),
      new google.maps.LatLng(0.11275824902536513, -71.37208730090667),
      new google.maps.LatLng(0.12378139870955795, -71.36912659292221),
      new google.maps.LatLng(0.1311301651656871, -71.36912659292221),
      new google.maps.LatLng(0.12474834166431226, -71.35876411497664),
      new google.maps.LatLng(0.11082436311585742, -71.35469314149802),
      new google.maps.LatLng(0.10173509934117231, -71.35469314149802),
      new google.maps.LatLng(0.09071194965697948, -71.3513623450155),
      new google.maps.LatLng(0.08433012615560465, -71.34266526531117),
      new google.maps.LatLng(0.0744673080171161, -71.33211774311656),
      new google.maps.LatLng(0.05822266637725271, -71.3258262386496),
      new google.maps.LatLng(0.044492076419749615, -71.31657402619818),
      new google.maps.LatLng(0.040237527418832464, -71.3062115482526),
      new google.maps.LatLng(0.04313835628309359, -71.30510128275843),
      new google.maps.LatLng(0.04603918514735561, -71.29695933580119),
      new google.maps.LatLng(0.03849703010027561, -71.28548659236144),
      new google.maps.LatLng(0.03269537237175335, -71.27919508789448),
      new google.maps.LatLng(0.02979454350749222, -71.26124579573873),
      new google.maps.LatLng(0.028440823370837087, -71.24977305229898),
      new google.maps.LatLng(0.03308214955365507, -71.2479226098087),
      new google.maps.LatLng(0.043525133464995314, -71.24755252131064),
      new google.maps.LatLng(0.05164745428492701, -71.24514694607328),
      new google.maps.LatLng(0.05783588919535099, -71.2388554416063),
      new google.maps.LatLng(0.05551522610394244, -71.23071349464907),
      new google.maps.LatLng(0.05667555764964671, -71.22331172468793),
      new google.maps.LatLng(0.06421771269672583, -71.22090614945057),
      new google.maps.LatLng(0.06247721537816897, -71.2099885387579),
      new google.maps.LatLng(0.057642500604400126, -71.1949999545866),
      new google.maps.LatLng(0.04217141332834018, -71.18519260938811),
      new google.maps.LatLng(0.011422627367170257, -71.17020402521682),
      new google.maps.LatLng(-0.008303008909806842, -71.16391252074986),
      new google.maps.LatLng(-0.008303008909806842, -71.15225473306108),
      new google.maps.LatLng(-0.00037407668082511947, -71.14596322859411),
      new google.maps.LatLng(-0.004822014272693131, -71.13264004266408),
      new google.maps.LatLng(-0.0141046666383291, -71.12875411343448),
      new google.maps.LatLng(-0.022807153231113375, -71.12930924618158),
      new google.maps.LatLng(-0.028608810959635633, -71.11765145849279),
      new google.maps.LatLng(-0.025707982095374504, -71.11210013102195),
      new google.maps.LatLng(-0.03150963982389676, -71.09988721058608),
      new google.maps.LatLng(-0.03402369150625706, -71.08027252018908),
      new google.maps.LatLng(-0.03750468614336988, -71.06768951125515),
      new google.maps.LatLng(-0.042726178099040446, -71.06583906876487),
      new google.maps.LatLng(-0.04852783582756359, -71.07046517499057),
      new google.maps.LatLng(-0.053169162010381577, -71.07157544048475),
      new google.maps.LatLng(-0.058390653966051254, -71.06935490949641),
      new google.maps.LatLng(-0.06999396942309666, -71.05436632532512),
      new google.maps.LatLng(-0.06303198014886924, -71.04844490935622),
      new google.maps.LatLng(-0.05026833314611956, -71.04974021909942),
      new google.maps.LatLng(-0.042919566689991306, -71.02771995346505),
      new google.maps.LatLng(-0.045820395554252435, -71.01680234277238),
      new google.maps.LatLng(-0.049301390191366146, -71.00995570555833),
      new google.maps.LatLng(-0.06148487142126324, -70.99256154614967),
      new google.maps.LatLng(-0.08353117078964978, -70.9672104840328),
      new google.maps.LatLng(-0.08701216542676349, -70.96147411231293),
      new google.maps.LatLng(-0.09977581242951317, -70.9540723423518),
      new google.maps.LatLng(-0.11253945943226284, -70.95055650162026),
      new google.maps.LatLng(-0.13748658766490962, -70.93149694397034),
      new google.maps.LatLng(-0.153731229304773, -70.91817375804031),
      new google.maps.LatLng(-0.16011305280614785, -70.91872889078739),
      new google.maps.LatLng(-0.16572132194372013, -70.92502039525435),
      new google.maps.LatLng(-0.16398082462516328, -70.94130428916884),
      new google.maps.LatLng(-0.16572132194372013, -70.94537526264746),
      new google.maps.LatLng(-0.1790651347193215, -70.95684800608721),
      new google.maps.LatLng(-0.1887345642668592, -70.95906853707555),
      new google.maps.LatLng(-0.20265854281531404, -70.9475957936358),
      new google.maps.LatLng(-0.21078086363524573, -70.94352482015718),
      new google.maps.LatLng(-0.2235445106379954, -70.94000897942564),
      new google.maps.LatLng(-0.22934616836651767, -70.92964650148006),
      new google.maps.LatLng(-0.23746848918644936, -70.92742597049173),
      new google.maps.LatLng(-0.24501064423352892, -70.91521305005585),
      new google.maps.LatLng(-0.256420571099623, -70.91299251906752),
      new google.maps.LatLng(-0.2674437207838163, -70.90947667833598),
      new google.maps.LatLng(-0.2761462073766001, -70.90133473137874),
      new google.maps.LatLng(-0.2900701859250545, -70.89615349240594),
      new google.maps.LatLng(-0.3119230967024893, -70.90133473137874),
      new google.maps.LatLng(-0.3391908880265455, -70.90022446588456),
      new google.maps.LatLng(-0.34615287730077293, -70.89097225343315),
      new google.maps.LatLng(-0.35195453502929563, -70.87598366926186),
      new google.maps.LatLng(-0.3693595082148633, -70.86858189930072),
      new google.maps.LatLng(-0.3809628236719087, -70.86043995234348),
      new google.maps.LatLng(-0.3885049787189878, -70.85174287263915),
      new google.maps.LatLng(-0.3850239840818741, -70.84489623542511),
      new google.maps.LatLng(-0.38154298944476084, -70.83397862473244),
      new google.maps.LatLng(-0.3817363780357117, -70.81436393433545),
      new google.maps.LatLng(-0.372066948488174, -70.7964146421797),
      new google.maps.LatLng(-0.36336446189538973, -70.79012313771274),
      new google.maps.LatLng(-0.3500206491197879, -70.78845773947148),
      new google.maps.LatLng(-0.3413181625270041, -70.78438676599286),
      new google.maps.LatLng(-0.3316487329794664, -70.76754773933129),
      new google.maps.LatLng(-0.32526690947809156, -70.7636618101017),
      new google.maps.LatLng(-0.33222889875231854, -70.7479330489343),
      new google.maps.LatLng(-0.3444123799822161, -70.72498756205479),
      new google.maps.LatLng(-0.3538884209388029, -70.70592800440488),
      new google.maps.LatLng(-0.3631710733044393, -70.69501039371221),
      new google.maps.LatLng(-0.37941571494430226, -70.6707695970895),
      new google.maps.LatLng(-0.37187355989722315, -70.66669862361088),
      new google.maps.LatLng(-0.36336446189538973, -70.65929685364975),
      new google.maps.LatLng(-0.36336446189538973, -70.64245782698818),
      new google.maps.LatLng(-0.36800578807820816, -70.63394579153288),
      new google.maps.LatLng(-0.38831159012803695, -70.62006747285575),
      new google.maps.LatLng(-0.4132587183606842, -70.59582667623305),
      new google.maps.LatLng(-0.4179000445435026, -70.58435393279329),
      new google.maps.LatLng(-0.40765044922311233, -70.56233366715892),
      new google.maps.LatLng(-0.4001082941760332, -70.56418410964922),
      new google.maps.LatLng(-0.40068845994888536, -70.55659729543905),
      new google.maps.LatLng(-0.40532978613170334, -70.54734508298765),
      new google.maps.LatLng(-0.4008818485398362, -70.5404984457736),
      new google.maps.LatLng(-0.4020421800855405, -70.53291163156344),
      new google.maps.LatLng(-0.40726367204121106, -70.52384446336106),
      new google.maps.LatLng(-0.41422566131543803, -70.51403711816255),
      new google.maps.LatLng(-0.42872980563674457, -70.50015879948543),
      new google.maps.LatLng(-0.4420736184123468, -70.48924118879276),
      new google.maps.LatLng(-0.47843067351108814, -70.46611065766423),
      new google.maps.LatLng(-0.48539266278531557, -70.46092941869144),
      new google.maps.LatLng(-0.4929348178323947, -70.45796871070698),
      new google.maps.LatLng(-0.5126604541093718, -70.45685844521282),
      new google.maps.LatLng(-0.5213629407021556, -70.45907897620116),
      new google.maps.LatLng(-0.5202026091564509, -70.44353525928278),
      new google.maps.LatLng(-0.5035711903346862, -70.4083768519674),
      new google.maps.LatLng(-0.5024108587889815, -70.39468357753931),
      new google.maps.LatLng(-0.5093728480632089, -70.38598649783498),
      new google.maps.LatLng(-0.5174951688831406, -70.37969499336802),
      new google.maps.LatLng(-0.5256174897030723, -70.36803720567924),
      new google.maps.LatLng(-0.5194290547926479, -70.34268614356236),
      new google.maps.LatLng(-0.5188488890197958, -70.32769755939108),
      new google.maps.LatLng(-0.5095662366541598, -70.34268614356236),
      new google.maps.LatLng(-0.5043447446984892, -70.35415888700211),
      new google.maps.LatLng(-0.4985430869699665, -70.36341109945353),
      new google.maps.LatLng(-0.4921612634685917, -70.36396623220062),
      new google.maps.LatLng(-0.48539266278531557, -70.35378879850407),
      new google.maps.LatLng(-0.47959100505679286, -70.33417410810706),
      new google.maps.LatLng(-0.48075133660249714, -70.31566968320423),
      new google.maps.LatLng(-0.47456290169207316, -70.30993331148436),
      new google.maps.LatLng(-0.46353975200788033, -70.30419693976448),
      new google.maps.LatLng(-0.45773809427935763, -70.29957083353878),
      new google.maps.LatLng(-0.45831826005220977, -70.28846817859709),
      new google.maps.LatLng(-0.4712752956459103, -70.26885348820008),
      new google.maps.LatLng(-0.47881745069298987, -70.2538649040288),
      new google.maps.LatLng(-0.47881745069298987, -70.23721092161625),
      new google.maps.LatLng(-0.4836521654667587, -70.21889154096245),
      new google.maps.LatLng(-0.4894538231952814, -70.20908419576395),
      new google.maps.LatLng(-0.5033778017437354, -70.20482817803631),
      new google.maps.LatLng(-0.5118868997455688, -70.20778888602075),
      new google.maps.LatLng(-0.5165282259283868, -70.21630092147606),
      new google.maps.LatLng(-0.5188488890197958, -70.22388773568622),
      new google.maps.LatLng(-0.5118868997455688, -70.23499039062791),
      new google.maps.LatLng(-0.5118868997455688, -70.23776605436333),
      new google.maps.LatLng(-0.5331596447501514, -70.25793587750742),
      new google.maps.LatLng(-0.544569571616246, -70.26940862094717),
      new google.maps.LatLng(-0.5600406588923064, -70.29198401932862),
      new google.maps.LatLng(-0.5865348958525596, -70.31141366547659),
      new google.maps.LatLng(-0.6078076408571422, -70.31677994869841),
      new google.maps.LatLng(-0.6211514536327445, -70.3160397717023),
      new google.maps.LatLng(-0.6234721167241535, -70.3121538424727),
      new google.maps.LatLng(-0.6159299616770739, -70.307527736247),
      new google.maps.LatLng(-0.5998787086281614, -70.29420455031696),
      new google.maps.LatLng(-0.6116754126761577, -70.27458985991996),
      new google.maps.LatLng(-0.6346886549992972, -70.2670030457098),
      new google.maps.LatLng(-0.6590556174590922, -70.2670030457098),
      new google.maps.LatLng(-0.6913515121478677, -70.26533764746856),
      new google.maps.LatLng(-0.7097234282881897, -70.25904614300158),
      new google.maps.LatLng(-0.7259680699280526, -70.25960127574868),
      new google.maps.LatLng(-0.7393118827036549, -70.26422738197438),
      new google.maps.LatLng(-0.7710276116195782, -70.25904614300158),
      new google.maps.LatLng(-0.7814705955309189, -70.25830596600548),
      new google.maps.LatLng(-0.7878524190322937, -70.27051888644134),
      new google.maps.LatLng(-0.802743340535502, -70.28606260335971),
      new google.maps.LatLng(-0.8102854955825816, -70.28957844409125),
      new google.maps.LatLng(-0.8315582405871642, -70.28365702812235),
      new google.maps.LatLng(-0.8431615560442096, -70.28421216086943),
      new google.maps.LatLng(-0.8576657003655161, -70.2768103909083),
      new google.maps.LatLng(-0.8692690158225611, -70.2623769394841),
      new google.maps.LatLng(-0.8936359782823562, -70.24220711634001),
      new google.maps.LatLng(-0.9137483917412346, -70.23573056762402),
      new google.maps.LatLng(-0.932313696472507, -70.23924640835556),
      new google.maps.LatLng(-0.9479781723395178, -70.24794348805989),
      new google.maps.LatLng(-0.9686707515712487, -70.26126667398992),
      new google.maps.LatLng(-0.9765996838002295, -70.26459747047244),
      new google.maps.LatLng(-0.9812410099830475, -70.27495994841802),
      new google.maps.LatLng(-0.9816277871649488, -70.29105879808348),
      new google.maps.LatLng(-0.9887831650301271, -70.29568490430918),
      new google.maps.LatLng(-0.9990327603505169, -70.2997558777878),
      new google.maps.LatLng(-1.0210790597189026, -70.29401950606793),
      new google.maps.LatLng(-1.044285690632993, -70.27958605464373),
      new google.maps.LatLng(-1.0539551201805306, -70.26922357669814),
      new google.maps.LatLng(-1.0541485087714815, -70.2414669393439),
      new google.maps.LatLng(-1.0415782503596827, -70.23924640835556),
      new google.maps.LatLng(-1.0344228724945048, -70.23924640835556),
      new google.maps.LatLng(-1.0253336087198193, -70.23110446139832),
      new google.maps.LatLng(-1.0166311221270354, -70.22018685070564),
      new google.maps.LatLng(-1.0168245107179863, -70.20057216030865),
      new google.maps.LatLng(-1.0243666657650654, -70.19539092133586),
      new google.maps.LatLng(-1.0526014000438755, -70.20223755854991),
      new google.maps.LatLng(-1.061884052409512, -70.21148977100133),
      new google.maps.LatLng(-1.0738741450484586, -70.22129711619982),
      new google.maps.LatLng(-1.0854774605055035, -70.22425782418426),
      new google.maps.LatLng(-1.096307221598746, -70.22018685070564),
      new google.maps.LatLng(-1.0982411075082532, -70.20575339928145),
      new google.maps.LatLng(-1.104622931009628, -70.1890994168689),
      new google.maps.LatLng(-1.1191270753309346, -70.18151260265874),
      new google.maps.LatLng(-1.1336312196522411, -70.17522109819177),
      new google.maps.LatLng(-1.1446543693364344, -70.16652401848745),
      new google.maps.LatLng(-1.1492956955192524, -70.15098030156908),
      new google.maps.LatLng(-1.1365320485165027, -70.1387673811332),
      new google.maps.LatLng(-1.122221292786147, -70.13192074391917),
      new google.maps.LatLng(-1.0990146618720562, -70.12618437219929),
      new google.maps.LatLng(-1.1003683820087118, -70.11878260223816),
      new google.maps.LatLng(-1.0957270558258938, -70.1106406552809),
      new google.maps.LatLng(-1.089925398097371, -70.09676233660379),
      new google.maps.LatLng(-1.0825766316412424, -70.08343915067375),
      new google.maps.LatLng(-1.073293979275606, -70.08417932766986),
      new google.maps.LatLng(-1.059370000727152, -70.08751012415237),
      new google.maps.LatLng(-1.0518278456800725, -70.09398667286837),
      new google.maps.LatLng(-1.0500873483615156, -70.10027817733533),
      new google.maps.LatLng(-1.0580162805904965, -70.12396384121095),
      new google.maps.LatLng(-1.0545352859533832, -70.13654685014487),
      new google.maps.LatLng(-1.0510542913162695, -70.14246826611377),
      new google.maps.LatLng(-1.032682375175948, -70.1446887971021),
      new google.maps.LatLng(-1.0210790597189026, -70.13654685014487),
      new google.maps.LatLng(-1.0125699617170696, -70.1210031332265),
      new google.maps.LatLng(-1.0032873093514332, -70.10656968180228),
      new google.maps.LatLng(-0.9860757247568164, -70.07677755770874),
      new google.maps.LatLng(-1.0088955784890055, -70.05068631859575),
      new google.maps.LatLng(-1.0104426872166115, -70.04661534511712),
      new google.maps.LatLng(-0.999612926123369, -70.03514260167738),
      new google.maps.LatLng(-0.975245963663574, -70.03699304416766),
      new google.maps.LatLng(-0.9677038086164949, -70.0294062299575),
      new google.maps.LatLng(-0.9800806784373428, -70.01201207054883),
      new google.maps.LatLng(-0.9903302737577331, -70.0097915395605),
      new google.maps.LatLng(-1.004254252306187, -70.00405516784062),
      new google.maps.LatLng(-1.0077352469433007, -69.999984194362),
      new google.maps.LatLng(-0.9998063147143199, -69.98333021194945),
      new google.maps.LatLng(-0.9986459831686152, -69.97518826499221),
      new google.maps.LatLng(-0.9957451543043541, -69.9705621587665),
      new google.maps.LatLng(-0.9895567193939301, -69.9659360525408),
      new google.maps.LatLng(-0.9837550616654074, -69.95908941532676),
      new google.maps.LatLng(-0.9854955589839642, -69.94817180463409),
      new google.maps.LatLng(-1.005221195260941, -69.92097030002692),
      new google.maps.LatLng(-1.0121831845351679, -69.91356853006579),
      new google.maps.LatLng(-1.0150840133994294, -69.91467879555996),
      new google.maps.LatLng(-1.0185650080365427, -69.92781693724098),
      new google.maps.LatLng(-1.026493940265524, -69.94188030016713),
      new google.maps.LatLng(-1.0415782503596827, -69.95039233562242),
      new google.maps.LatLng(-1.057049337635743, -69.94743162763797),
      new google.maps.LatLng(-1.0622708295914132, -69.94299056566129),
      new google.maps.LatLng(-1.09224606118878, -69.9509474683695),
      new google.maps.LatLng(-1.105589873964382, -69.96889676052525),
      new google.maps.LatLng(-1.1106179773291016, -69.97278268975484),
      new google.maps.LatLng(-1.12299484714995, -69.97407799949804),
      new google.maps.LatLng(-1.1264758417870633, -69.96760145078206),
      new google.maps.LatLng(-1.1316973337427338, -69.96242021180926),
      new google.maps.LatLng(-1.132277499515586, -69.95372313210493),
      new google.maps.LatLng(-1.129956836424177, -69.95039233562242),
      new google.maps.LatLng(-1.1270560075599159, -69.93762428243947),
      new google.maps.LatLng(-1.1206741840585406, -69.92966737973126),
      new google.maps.LatLng(-1.1173865780123782, -69.92041516727984),
      new google.maps.LatLng(-1.1139055833752645, -69.90413127336535),
      new google.maps.LatLng(-1.1133254176024123, -69.8874772909528),
      new google.maps.LatLng(-1.1106179773291016, -69.87655968026014),
      new google.maps.LatLng(-1.0897320095064202, -69.86157109608885),
      new google.maps.LatLng(-1.0719402591389509, -69.8397358747035),
      new google.maps.LatLng(-1.0692328188656401, -69.82474729053223),
      new google.maps.LatLng(-1.0818030772774394, -69.82123144980068),
      new google.maps.LatLng(-1.0847039061417005, -69.81660534357498),
      new google.maps.LatLng(-1.108684091419594, -69.79828596292118),
      new google.maps.LatLng(-1.1202874068766393, -69.79069914871101),
      new google.maps.LatLng(-1.1305370021970291, -69.78607304248531),
      new google.maps.LatLng(-1.1357584941526997, -69.7716395910611),
      new google.maps.LatLng(-1.1371122142893548, -69.75146976791703),
      new google.maps.LatLng(-1.1318907223336847, -69.74517826345006),
      new google.maps.LatLng(-1.1249287330594573, -69.7427726882127),
      new google.maps.LatLng(-1.1168064122395256, -69.76423782109998),
      new google.maps.LatLng(-1.1081039256467418, -69.76423782109998),
      new google.maps.LatLng(-1.1005617705996622, -69.76812375032956),
      new google.maps.LatLng(-1.091472506824977, -69.76812375032956),
      new google.maps.LatLng(-1.091472506824977, -69.77330498930236),
      new google.maps.LatLng(-1.0814163000955377, -69.78144693625961),
      new google.maps.LatLng(-1.0723270363208526, -69.77330498930236),
      new google.maps.LatLng(-1.069426207456591, -69.76072198036844),
      new google.maps.LatLng(-1.0688460416837389, -69.75091463516993),
      new google.maps.LatLng(-1.072520424911803, -69.73185507752002),
      new google.maps.LatLng(-1.0818030772774394, -69.71816180309193),
      new google.maps.LatLng(-1.0934063927344844, -69.70243304192454),
      new google.maps.LatLng(-1.1125518632386093, -69.68873976749644),
      new google.maps.LatLng(-1.1235750129228022, -69.68466879401782),
      new google.maps.LatLng(-1.1357584941526997, -69.67412127182321),
      new google.maps.LatLng(-1.1438808149726314, -69.66338870537956),
      new google.maps.LatLng(-1.1547105760658734, -69.65987286464802),
      new google.maps.LatLng(-1.1639932284315098, -69.66782976735624),
      new google.maps.LatLng(-1.1639932284315098, -69.67819224530183),
      new google.maps.LatLng(-1.1732758807971457, -69.68337348427463),
      new google.maps.LatLng(-1.188166802300354, -69.67930251079599),
      new google.maps.LatLng(-1.2084726043501828, -69.68170808603337),
      new google.maps.LatLng(-1.2309056809004701, -69.68688932500615),
      new google.maps.LatLng(-1.2436693279032198, -69.68281835152753),
      new google.maps.LatLng(-1.258753637997379, -69.67134560808778),
      new google.maps.LatLng(-1.2622346326344922, -69.659687820399),
      new google.maps.LatLng(-1.257786695042625, -69.63951799725491),
      new google.maps.LatLng(-1.2322594010371257, -69.63896286450783),
      new google.maps.LatLng(-1.2179486453067696, -69.63045082905253),
      new google.maps.LatLng(-1.2057651640768725, -69.61472206788513),
      new google.maps.LatLng(-1.2034445009854635, -69.60621003242983),
      new google.maps.LatLng(-1.201897392257857, -69.59066631551146),
      new google.maps.LatLng(-1.2100197130777888, -69.58011879331684),
      new google.maps.LatLng(-1.223363525853391, -69.57160675786155),
      new google.maps.LatLng(-1.2239436916262432, -69.5595788816747),
      new google.maps.LatLng(-1.2262643547176522, -69.548476226733),
      new google.maps.LatLng(-1.2384478359475497, -69.52886153633601),
      new google.maps.LatLng(-1.2506313171774472, -69.5110972884293),
      new google.maps.LatLng(-1.2519850373141024, -69.48685649180659),
      new google.maps.LatLng(-1.2438627164941707, -69.4816752528338),
      new google.maps.LatLng(-1.2330329554009287, -69.47760427935518),
      new google.maps.LatLng(-1.2167883137610653, -69.47815941210226),
      new google.maps.LatLng(-1.2040246667583157, -69.48907702279493),
      new google.maps.LatLng(-1.1901006882098613, -69.49370312902063),
      new google.maps.LatLng(-1.188940356664157, -69.48056498733963),
      new google.maps.LatLng(-1.1815915902080283, -69.47464357137072),
      new google.maps.LatLng(-1.1665072801138692, -69.47593888111392),
      new google.maps.LatLng(-1.1537436331111195, -69.48463596081825),
      new google.maps.LatLng(-1.1411733746993207, -69.49037233253813),
      new google.maps.LatLng(-1.128409727696571, -69.48852189004785),
      new google.maps.LatLng(-1.1278295619237189, -69.47593888111392),
      new google.maps.LatLng(-1.1168064122395256, -69.45928489870138),
      new google.maps.LatLng(-1.1146791377390675, -69.44836728800871),
      new google.maps.LatLng(-1.1152593035119196, -69.43615436757284),
      new google.maps.LatLng(-1.0785154712312766, -69.4313432170981),

    ];
    this.ptsList = JSON.stringify(this.polygonPts);
  }

  mapClick(event) {

    // For some reason the push function does not work
    // but using the spread operator does.

    let ll = new google.maps.LatLng(event.coords.lat, event.coords.lng);

    // this will fail
    this.polygonPts.push(ll);

    //uncomment this to get it to work.
    //this.polygonPts = [...this.polygonPts, ll];
    this.ptsList = JSON.stringify(this.polygonPts);
  }

  mouseOver() {

    window.alert('Hello world');
    // console.log("Se Activo");
  }

  clear() { this.polygonPts = []; }

  ///////////////////////////////////
  onMapReady(map) {
    this.initDrawingManager(map);
  }


  initDrawingManager(map: any) {

    const options = {
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: ["polygon"]
      },
      polygonOptions: {
        draggable: true,
        editable: true
      },
      drawingMode: google.maps.drawing.OverlayType.POLYGON
    };

    const drawingManager = new google.maps.drawing.DrawingManager(options);
    drawingManager.setMap(map);

    google.maps.event.addListener(drawingManager, 'overlaycomplete', (event) => {
      // Polygon drawn 
      if (event.type === google.maps.drawing.OverlayType.POLYGON) {
        //this is the coordinate, you can assign it to a variable or pass into another function.
        //  alert(event.overlay.getPath().getArray());
        //  console.log(event.overlay.getPath().getArray());
        let cadena: string = event.overlay.getPath().getArray();
        console.log('Cadena:' + cadena);
      }
    });

  }

  mapClick2(event) {
    debugger;
    console.log(event.coords.lat, event.coords.lng);
    // For some reason the push function does not work
    // but using the spread operator does.

    let ll = new google.maps.LatLng(event.coords.lat, event.coords.lng);

    // this will fail
    this.polygonPts.push(ll);

    //uncomment this to get it to work.
    //this.polygonPts = [...this.polygonPts, ll];
    this.ptsList = JSON.stringify(this.polygonPts);
  }

  ////////////////////////////////////////////////

}


export class Country {
  totalArea: number;
  color: string;
}

export class Countries {
  Russia: Country;
  Canada: Country;
  China: Country;
  "United States": Country;
  Brazil: Country;
  Australia: Country;
  India: Country;
  Argentina: Country;
  Kazakhstan: Country;
  Algeria: Country;
  Colombia: Country;
}




