import { RouterModule, Routes } from "@angular/router";

//Componentes
import { LoginComponent } from "./login/login.component";
import { NopagefoundComponent } from "./shared/nopagefound/nopagefound.component";
import { RegisterComponent } from "./login/register.component";
import { HomeComponent } from "./home/home.component";
import { NewHomeComponent } from './new-home/new-home.component';
import { NoticiasComponent } from "./new-home/noticias/noticias.component";
import { EstadisticasComponent } from "./new-home/estadisticas/estadisticas.component";
import { DetalleNoticiasComponent } from "./new-home/detalle-noticias/detalle-noticias.component";
import { CatalogoDocumentalComponent } from "./new-home/catalogo-documental/catalogo-documental.component";
import { CalendarioComponent } from "./new-home/calendario/calendario.component";
import { ValidarCuentaComponent } from "./login/validar-cuenta.component";
import { MapaSonoroComponent } from "./mapa-sonoro/mapa-sonoro.component";
import { DetalleCatalogoComponent } from "./new-home/detalle-catalogo/detalle-catalogo.component";

const appRoutes: Routes = [
  { path: "old-home", component: HomeComponent },
  { path: "home", component: NewHomeComponent },
  { path: "noticias", component: NoticiasComponent },
  { path: "estadisticas", component: EstadisticasComponent },
  { path: "detalle-noticias/:id", component: DetalleNoticiasComponent },
  { path: "home-memoria", component: CatalogoDocumentalComponent },
  { path: "home-catalogo", component: CatalogoDocumentalComponent },
  { path: "detalle-catalogo/:id", component: DetalleCatalogoComponent },
  { path: "calendario", component: CalendarioComponent },
  { path: "login", component: LoginComponent },
  { path: "register", component: RegisterComponent },
  { path: "validar-cuenta", component: ValidarCuentaComponent },
  { path: "mapa-sonoro", component: MapaSonoroComponent },
  { path: "**", component: NopagefoundComponent },
];

export const APP_ROUTES = RouterModule.forRoot(appRoutes, { useHash: true, scrollPositionRestoration: 'enabled' });
