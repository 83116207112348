import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EXP_SENSORIAL_ROUTES } from './exp-sensorial.routes';
import { AudiovisualSonoroComponent } from './pages/audiovisual-sonoro/audiovisual-sonoro.component';
import { HeaderGovCoComponent } from '../components/header-gov-co/header-gov-co.component';
import { FooterGovCoComponent } from '../components/footer-gov-co/footer-gov-co.component';
import { EditorialComponent } from './pages/editorial/editorial.component';
import { GridComponent } from './pages/grid/grid.component';
import { SafePipe } from '../shared/pipes/Safe.Pipe';



@NgModule({
  declarations: [
    AudiovisualSonoroComponent,
    HeaderGovCoComponent,
    FooterGovCoComponent,
    EditorialComponent,
    GridComponent,
    SafePipe
  ],
  imports: [
    CommonModule,
    EXP_SENSORIAL_ROUTES
  ],
  exports: [
    HeaderGovCoComponent,
    FooterGovCoComponent,
  ]
})
export class ExpSensorialModule { }
