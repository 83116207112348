//Router
import { RouterModule, Routes } from '@angular/router';

//Componentes
import { ExpSensorialComponent } from './exp-sensorial.component';
import { AudiovisualSonoroComponent } from './pages/audiovisual-sonoro/audiovisual-sonoro.component';
import { EditorialComponent } from './pages/editorial/editorial.component';
import { GridComponent } from './pages/grid/grid.component';




const expSensorialRoutes: Routes = [
  { path: 'exp-sensorial', component: ExpSensorialComponent },
  { path: 'audiovisual-sonoro', component: AudiovisualSonoroComponent, data: { titulo: 'Proyecto audiovisual y sonoro' } },
  { path: 'editorial', component: EditorialComponent, data: { titulo: 'Proyecto Editorial' } },
  { path: 'grid', component: GridComponent, data: { titulo: 'Proyecto Editorial' } },
];

export const EXP_SENSORIAL_ROUTES = RouterModule.forChild(expSensorialRoutes);
