import { Injectable } from '@angular/core';
import { URL_SERVICIOS, URL_SERVICIOS_EVENTOS } from '../../config/config';
import { HttpClient, HttpHeaders } from '@angular/common/http';



@Injectable({
    providedIn: 'root'
})
export class NewHomeService {

    public CatalogoId = 0;
    OperacionExitosa = true;

    constructor(public http: HttpClient) { }

    getEventosXfecha(FechaInicial: string, FechaFinal: string) {

        let body = { FechaInicial, FechaFinal }

        let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*');;

        return this.http.post(URL_SERVICIOS_EVENTOS + 'api/eventosfecha', body);

    }


    getNoticias() {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Noticia/getNoticias');

    }

    getNoticiaXid(id) {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Noticia/getNoticiasById?NoticiaId=' + id);

    }

    getListaCatalogo(areaId, categoriaId, tipopublicacionId) {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/getCatalogoHome?areaId=' + areaId +'&categoriaId=' + categoriaId +'&tipopublicacionId=' + tipopublicacionId);

    }

    getcatalogoXid(id) {

        return this.http.get(URL_SERVICIOS + 'ApiRest/Catalogo/getHomeCatalogoById?CatalogoId=' + id);

    }

}