import { Component, OnInit } from '@angular/core';
import { Router
 } from '@angular/router';
import { TipoReferenciaService } from 'src/app/services/service.index';
import { IOption } from 'ng-select';
import { NewHomeService } from 'src/app/services/new-home/new-home.service';
import { Catalogo } from 'src/app/models/catalogo.model';
import { URL_SERVICIOS } from 'src/app/config/config';
import { asLiteral } from '@angular/compiler/src/render3/view/util';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-catalogo-documental',
  templateUrl: './catalogo-documental.component.html',
  styleUrls: ['./catalogo-documental.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css']
})
export class CatalogoDocumentalComponent implements OnInit {

  listaCatalogos = [];

  areaLista: Array<IOption>;
  categoriaLista: Array<IOption>;

  tipoPublicacionLista: Array<IOption>;

  catalogo: Catalogo;
  showCatalogoImage = true;
  memoriaSocial = false;

  constructor(
    public _tipoReferenciaService: TipoReferenciaService,
    public _newHomeService: NewHomeService,
    private router: Router
  ) {

    if(router.url == '/home-catalogo'){
      this.memoriaSocial = false;
    }
    else{
      this.memoriaSocial = true;
    }
    this.catalogo = new Catalogo(
      null,
      -1,
      -1,
      null,
      null,
      -1,
      null,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      null,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      null,
      '',
      '',
      '',
      false,
      [],
      null,
      ''
    );
  }

  ngOnInit() {

    let categoria;
    if(this.memoriaSocial){
      categoria = 6153;
      this.catalogo.CategoriaId = categoria;
    }
    else{
      categoria = -1;
    }
    this.cargarCatalogos(-1, categoria, -1);
    this.cargarAreas();
    this.cargarCategoria();
    this.cargarTipoPublicacion();

  }

  cargarAreas() {

    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorId(3082)
      .subscribe(
        (result) => {
          this.areaLista = result.Lista;
        },
        (error) => {

          console.log(<any>error);
        }
      );
  }

  cargarCategoria() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorId(3101)
      .subscribe(
        (result) => {
          this.categoriaLista = result.Lista;
        },
        (error) => {

          console.log(<any>error);
        }
      );
  }

  cargarTipoPublicacion() {
    this._tipoReferenciaService
      .getValorReferenciaPorTipoValorId(3102)
      .subscribe(
        (result) => {
          this.tipoPublicacionLista = result.Lista;
        },
        (error) => {

          console.log(<any>error);
        }
      );
  }

  cargarCatalogos(areaId, categoriaId, tipopublicacionId) {
    this._newHomeService.getListaCatalogo(areaId, categoriaId, tipopublicacionId).subscribe((resp: any) => {      
      this.listaCatalogos  =this.agruparListaPorArea(resp.Lista);     
    });
  }

  agruparListaPorArea(items: any): any{
    const listaAgrupadaPorArea = []

    const map = new Map();

    for(const item of items){
      if(!map.has(item.NombreArea)){
        map.set(item.NombreArea, []);
      }
      map.get(item.NombreArea).push(item)
    }

    map.forEach((value, key)=>{
      listaAgrupadaPorArea.push({area: key, catalogos: value.sort((a, b)=> a.CatalogoEditorialTitulo.localeCompare(b.CatalogoEditorialTitulo))});
    })
    return listaAgrupadaPorArea;
   
  }
  UrlImagen(ArchivoLink) {
    return URL_SERVICIOS + 'Medios/CatalogoEditorial/' + ArchivoLink;
  }

  onSubmit() {

    this.cargarCatalogos(this.catalogo.AreaId, this.catalogo.CategoriaId, this.catalogo.TipoPublicacionId);

  }

  hideCatalogoImage(){
    this.showCatalogoImage = false;
  }

}
