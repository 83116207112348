import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exp-sensorial',
  templateUrl: './exp-sensorial.component.html',
  styleUrls: ['../../assets/css/css.css']
})
export class ExpSensorialComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
