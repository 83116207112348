import { Component, OnInit } from '@angular/core';

import maestrosConfig from '../../../../assets/json/maestros.json';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.css','../../../../assets/css/css.css']
})
export class GridComponent implements OnInit {

listMaestros = maestrosConfig;

showList = true;

maestro = null;

  constructor() { }

  ngOnInit() {
    
  }

  OnShowDetail(objMaestro){
    this.maestro = objMaestro;
    this.showList = false;
    window.scrollTo(0, 0);
  }

  OnShowList(){
    this.maestro = null;
    this.showList = true;
    window.scrollTo(0, 0);
  }

}
