
export class AgenteCultura {
    constructor(
        public AgenteNumeroIdentificacion: string,
        public InformacionPersonal: AgenteCulturaInformacionPersonal,
        public Ocupaciones: AgenteCulturaOcupacion[],
        public Formaciones: AgenteCulturaFormacion[],
        public Experiencias: AgenteCulturaExperiencia[],



    ) { }
}


export class AgenteCulturaInformacionPersonal {
    constructor(
        public IdUsuario: number,
        public TipoDcto: string,
        public Nombre: string,
        public PriNombre: string,
        public SegNombre: string,
        public PriApellido: string,
        public SegApellido: string,
        public NombreArtistico: string,
        public Edad: number,
        public Sexo: string,
        public IdentidadGenero: string,
        public GrupoEtnico: string,
        public Discapacidad: string,
        public GrupoPoblacional: string,
        public TieneRut: string,
        public PaisOrigen: string,
        public PaisResidencia: string,
        public AgenteFechaEdito: string,
        public Departamento: string,
        public Municipio: string,
        public CentroPoblado: string,
        public Zona: string,
        public Estrato: string,
        public RegimenSalud: string,
        public RegimenPension: string,
        public IngresosMensuales: string,
        public Actividad: string,
        public PortadorPatrimonio: string,
        public Manifestacion: string,
        public Completo: number,
        public FechaRegistro: number,
        public FechaUltActualizacion: string
    ) { }
}


export class AgenteCulturaOcupacion {
    constructor(
        public IdUsuario: number,
        public OcuId: number,
        public NombreOcupacion: string,
        public Sector: string,
        public Area: string,
        public Cuoc: string
    ) { }
}


export class AgenteCulturaFormacion {
    constructor(
        public IdUsuario: number,
        public NivelFormacion: number,
        public TituloObtenido: string,
        public Institucion: string,
        public Descripcion: string,
        public FechaIni: string,
        public FechaFin: string,
        public UsuAdjunto: string
    ) { }
}

export class AgenteCulturaExperiencia {
    constructor(
        public IdUsuario: number,
        public TipoExperiencia: number,
        public Entidad: string,
        public Cargo: string,
        public Anios: string,
        public Descripcion: string,
        public FechaIni: string,
        public FechaFin: string,
        public Adjunto: string
    ) { }
}


export enum AgenteCulturaTipoIdentificacion {
    "CÉDULA DE CIUDADANÍA" = 9,
    "CÉDULA DE EXTRANJERÍA" = 10,
    "PASAPORTE" = 11,
    "NÚMERO IDENTIFICACIÓN PERSONAL" = 13,
    "PERMISO POR PROTECCIÓN TEMPORAL" = 6141,
    "PERMISO ESPECIAL DE PERMANENCIA" = 4107
}