import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NewHomeService } from '../services/new-home/new-home.service';

@Component({
  selector: 'app-new-home',
  templateUrl: './new-home.component.html',
  styleUrls: ['./new-home.component.css', '../../assets/css/new-home/css/main.css', '../../assets/css/new-home/css/menu.css']
})
export class NewHomeComponent implements OnInit {

  constructor(
    public _newHomeService: NewHomeService,
  ) { }

  listaNoticias = [];

  listaEventos = [];
  objEvento;
  popupVisible: boolean = false;

  ngOnInit() {
    this.cargarNoticias();
    this.cargarEventos();
  }

  cargarNoticias() {
    this._newHomeService.getNoticias().subscribe((resp: any) => {
      this.listaNoticias = resp.Lista.slice(0, 3);
    });
  }

  obtenerNombreMes(numero) {
    numero = Number(numero);
    let miFecha = new Date();
    if (0 < numero && numero <= 12) {
      miFecha.setMonth(numero - 1);
      return (new Intl.DateTimeFormat('es-ES', { month: 'short' }).format(miFecha)).toUpperCase();
    } else {
      return null;
    }
  }

  cargarEventos() {
    const date = new Date().toLocaleDateString();
    const strDate = date.split('/')[2] + '-' + date.split('/')[1] + '-' + date.split('/')[0];
    this._newHomeService.getEventosXfecha(strDate, strDate).subscribe((resp: any) => {
      this.listaEventos = resp.slice(0, 10);;
    });
  }

  showModal(objEvento) {
    this.objEvento = objEvento;
    this.popupVisible = true;
  }

  popupHiding() {
    this.popupVisible = false;

  }

}
