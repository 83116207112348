import { Component, OnInit, Injectable } from '@angular/core';
import { UsuarioService } from 'src/app/services/service.index';
import Query from 'devextreme/data/query';
import { IOption } from 'ng-select';
import { TipoReferenciaService } from 'src/app/services/service.index';
import { getLocaleDateFormat } from '@angular/common';
@Component({
  selector: 'app-comisiones-calendario',
  templateUrl: './comisiones-calendario.component.html',
  styleUrls: ['./comisiones-calendario.component.css']
})
export class ComisionesCalendarioComponent implements OnInit {


    depLista: Array<IOption>;
    areasLista: Array<IOption>;
    public AreaId: string;
    public ZON_ID_DEP: string;
    munLista: Array<IOption>;
    public MunicipioId: string;
    public FechaInicial: Date;
    public FechaFin: Date;
    

    appointmentsData: Appointment[];
    currentDate: Date = new Date();
    currentDate2: Date = new Date(2015, 4, 25);

    formattedDate: string;
    formattedDate2: string;



    resources: Resource[];
    
    title = 'Mapa Comisiones';
    lat: number = 5.065781;
    lng: number = -75.507820;
    zoom: number = 6;

  constructor( public _usuarioService: UsuarioService, public  _tipoReferenciaService: TipoReferenciaService) {
    this.AreaId = '0';
    this.ZON_ID_DEP = '0';
    this.MunicipioId = '0';
   // this.cargarComisiones();

   }

   cargarfechasIniciales(){

    // Fecha actual
    const fechaActual = new Date();
       
    // Asignar la fecha de fin (fecha actual)
    this.FechaFin = fechaActual;

    // Calcular la fecha inicial restando un mes
    const fechaInicial = new Date(fechaActual);
    fechaInicial.setMonth(fechaActual.getMonth() - 1);

    // Asignar la fecha inicial
    this.FechaInicial = fechaInicial;

   }

  

   onSchedulerOptionChanged(event: any) {
     if (event.name === 'currentDate') {
       const currentMonth = event.value.getMonth() + 1; // Mes actual (0 es enero)
       const currentYear = event.value.getFullYear();
      
     }
     this.FechaFin = this.currentDate;

     // Calcular la fecha inicial restando un mes
     const fechaInicial = new Date(this.currentDate);
     fechaInicial.setMonth(fechaInicial.getMonth() - 1);
 
     // Asignar la fecha inicial
     this.FechaInicial = fechaInicial;

     this.cargarComisiones();

   }
   


   mostrarMunicipios() {
    this._tipoReferenciaService.getMunicipiosComisionesByDep(this.ZON_ID_DEP).subscribe(
        result => {
            this.munLista = result.Lista;
        },
        error => {
            // this.error = error;
            // if (error.statusText === 'Unauthorized') {
            //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
            //     this.authenticationService.logout().subscribe(response => { });
            //     this.storageService.logout();
            // }
            console.log(<any>error);
        }
    );
   // this.cargarComisiones();
  }

  CargarFechas(){
    var year1 = this.currentDate.getFullYear();
    var month1 = this.currentDate.getMonth();


    var fechain = new Date(year1, month1, 1); // Recordar que los meses empiezan en 0, por lo que 4 es mayo

        // Obtener cada componente de la fecha
        var year = fechain.getFullYear();
        var month = (fechain.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0, por eso sumamos 1
        var day = fechain.getDate().toString().padStart(2, '0');
        var hours = fechain.getHours().toString().padStart(2, '0');
        var minutes = fechain.getMinutes().toString().padStart(2, '0');
        var seconds = fechain.getSeconds().toString().padStart(2, '0');
        var milliseconds = fechain.getMilliseconds().toString().padStart(3, '0');

        // Formatear la fecha
        this.formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

        var fechaFin = new Date(year1, month1 + 1, 0);
        // Obtener cada componente de la fecha
        var year = fechaFin.getFullYear();
        var month = (fechaFin.getMonth() + 1).toString().padStart(2, '0'); // Los meses empiezan en 0, por eso sumamos 1
        var day = fechaFin.getDate().toString().padStart(2, '0');
        var hours = fechaFin.getHours().toString().padStart(2, '0');
        var minutes = fechaFin.getMinutes().toString().padStart(2, '0');
        var seconds = fechaFin.getSeconds().toString().padStart(2, '0');
        var milliseconds = fechaFin.getMilliseconds().toString().padStart(3, '0');

        // Formatear la fecha
        this.formattedDate2 = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

  }

   cargarComisiones() {

    this.CargarFechas();
    this._usuarioService.cargarComisiones(this.AreaId, this.ZON_ID_DEP, this.MunicipioId, this.formattedDate, this.formattedDate2)
              .subscribe(
                (resp: any) => {
                 this.appointmentsData = resp.Lista;
                 this.resources = resp.Lista;
                 if (this.appointmentsData !== null) {
                    for (let i = 0; i < this.appointmentsData.length; i++) {
                      this.appointmentsData[i].Latitud = String((Number(this.appointmentsData[i].Latitud)) +  (Math.random() - .5) / 1500);
                      this.appointmentsData[i].Longitud = String((Number(this.appointmentsData[i].Longitud)) +  (Math.random() - .5) / 1500);

                      this.appointmentsData[i].startDate = resp.Lista[i].FechaSalida;
                      this.appointmentsData[i].endDate = resp.Lista[i].FechaRegreso;
                      this.appointmentsData[i].description = 'Empleado: ' + resp.Lista[i].Nombres + ' ' +resp.Lista[i].Apellidos + ' - Objetivo: ' + resp.Lista[i].Objeto + 'Ubicacion: ' + resp.Lista[i].Ciudad + ', '+ resp.Lista[i].Departamento ;

                      this.resources[i].id = resp.Lista[i].id;
                      this.resources[i].text = resp.Lista[i].Nombres + ' ' +resp.Lista[i].Apellidos;

  
                    }
                }  
              });
             
  }

  
  // Método para generar colores aleatorios (opcional)
  getRandomColor(): string {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  ngOnInit() {
      this.getAreas();
      this.getDep();
  }




  getAreas() {
    this._usuarioService.cargarDependenciasComisiones( )
              .subscribe(
                (resp: any) => {
                //console.log(resp);
                //debugger;
                // this.totalRegistros = resp.total;
                 //this.usuarios = resp.usuarios;
                 this.areasLista = resp.Lista;
                // this.cargando = false;
              });

  }

    
    
 
getDep() {

      this._tipoReferenciaService.getDepartamentosComisiones().subscribe((resp: any) => {
              this.depLista = resp.Lista;
              console.log(resp.Lista);
          },
          error => {
              console.log(<any>error);
          }
      );
      console.log(this.depLista);
  }

}

@Injectable()
export class Service {
    // getAppointments(): Appointment[] {
    //     return appointments;
    // }
}

export class Resource {
    text: string;
    id: number;
    color: string;
}

export class Appointment {
  text: string;
  startDate: Date;
  endDate: Date;
  description: string;
  allDay?: boolean;
  idDependencia: number[];
  Latitud: string;
  Longitud: string;
  id: number;
  DependenciaNombre: string;
  Objeto: string;
}


export class Data {
  theatreId: number;
  movieId: number;
  price: number;
  startDate: Date;
  endDate: Date;
}

export class MovieData {
  id: number;
  text: string;
  director: string;
  year: number;
  image: string;
  duration: number;
  color: string;
}

export class TheatreData {
  text: string;
  id: number;
}

