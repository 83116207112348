import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URL_SERVICIOS } from 'src/app/config/config';
import { NewHomeService } from 'src/app/services/new-home/new-home.service';
import { CatalogoService } from 'src/app/services/service.index';

@Component({
  selector: 'app-detalle-catalogo',
  templateUrl: './detalle-catalogo.component.html',
  styleUrls: ['./detalle-catalogo.component.css', '../new-home.component.css', '../../../assets/css/new-home/css/main.css', '../../../assets/css/new-home/scss/components/noticias/detalle-noticias.scss']
})
export class DetalleCatalogoComponent implements OnInit {

  constructor(public _newHomeService: NewHomeService,
    public _catalogoService: CatalogoService,
    private activatedRoute: ActivatedRoute) { }

  listaCatalogo = [];
  catalogo: any;


  ngOnInit() {

    this.cargarCatalogos();
    this.activatedRoute.params.subscribe(params => {
      let id = params['id'];
      this.cargarCatalogoxid(id);

    });
  }

  cargarCatalogos() {
    this._newHomeService.getListaCatalogo(-1, -1, -1).subscribe((resp: any) => {
      this.listaCatalogo = resp.Lista.slice(0, 3);
    });
  }

  UrlImagen(ArchivoLink) {

    return URL_SERVICIOS + 'Medios/CatalogoEditorial/' + ArchivoLink;
  }

  cargarCatalogoxid(id) {

    this._newHomeService.getcatalogoXid(id).subscribe((resp: any) => {
      this.catalogo = resp;

    });
  }

  descargarArchivo(id: number) {
    if (id !== null) {

      this._catalogoService.downloadMediosDocumentoById(id).subscribe(
        (res) => {
          const url = window.URL.createObjectURL(res.body);
          const fileName = res.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove(); // remove the element
        },
        (error) => {
          console.log(<any>error);
        }
      );
    }
  }

}
