import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { IOption } from 'ng-select';
import { URL_SERVICIOS } from 'src/app/config/config';
import { Catalogo, Archivos } from 'src/app/models/catalogo.model';
import { User } from 'src/app/models/user.model';
import { CatalogoService, TipoReferenciaService, UsuarioService } from 'src/app/services/service.index';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-catalogo-editorial',
    templateUrl: './catalogo-editorial.component.html',
    styleUrls: ['./catalogo-editorial.component.css']
})
export class CatalogoEditorialComponent implements OnInit {

    areaLista: Array<IOption>;
    categoriaLista: Array<IOption>;
    subCategoriaLista: Array<IOption>;
    subSubCategoriaLista: Array<IOption>;
    tipoPublicacionLista: Array<IOption>;
    ambitoLista: Array<IOption>;
    tipoArchivoLista: Array<IOption>;
    estadoLista: Array<IOption>;

    catalogo: Catalogo;
    archivo: Archivos;

    loading = false;
    error: any;

    displayListCatalogo: boolean = true;
    displayCrearCatalogo: boolean = false;
    displayCatalogoArchivo: boolean = true;
    popupVisibleRegistroArchivo: boolean = false;

    listaCatalogos = [];
    listaCatalogoArchivo = [];

    valueArchivo: any[] = [];
    value: any[] = [];

    selectedItemsPractica: any[] = [];
    PracticaLista: DataSource;
    retencionLista: [];
    datos = [];

    usuario: User;
    usuId: number;

    accionArchivo: string;
    url: string;
    urlImagen: string;
    urlArchivo: string;

    disableCatalogoEditorialISMN = true;

    constructor(
        public _tipoReferenciaService: TipoReferenciaService,
        public _catalogoService: CatalogoService,
        public _usuarioService: UsuarioService
    ) {
        this.limpiarCatalogo();
        this.limpiarArchivo();

        this.selectedItemsPractica = [];


        const user: any = this._usuarioService.getCurrentUser();
        this.usuario = JSON.parse(user);
        // this.usuarioNombre = this.usuario.USU_NOMBRE;
        // this.email = this.usuario.USU_CORREO_ELECTRONICO;
        this.usuId = this.usuario.USU_ID;
    }

    ngOnInit(): void {

        this.cargarCatalogos();
        this.cargarAreas();
        this.cargarCategoria();
        this.cargarTipoPublicacion();
        this.cargarAmbito();
        this.cargarEstados();
    }

    cargarCatalogos() {
        this._catalogoService.getListaCatalogo().subscribe((resp: any) => {
            // debugger;
            // console.log(resp.Lista);
            this.listaCatalogos = resp.Lista;
        });
    }

    limpiarCatalogo() {

        this.urlImagen = '';
        this.catalogo = new Catalogo(
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            '',
            '',
            '',
            '',
            '',
            null,
            null,
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            null,
            '',
            '',
            '',
            false,
            [],
            null,
            ''
        );

        this.PracticaLista = new DataSource({
            store: new ArrayStore({
                key: 'id',
                data: [],
            }),
        });
    }

    limpiarArchivo() {
        this.archivo = new Archivos(
            null,
            null,
            null,
            null,
            '',
            '',
            '',
            '',
            null,
            null,
            null,
            ''
        );
    }

    showPanel(sw: string, id: any) {
        if (sw === 'C') {
            // Crear Catalogo
            this.displayListCatalogo = false;
            this.displayCrearCatalogo = true;
            this.displayCatalogoArchivo = false;
            this.limpiarCatalogo();
            this.limpiarArchivo();
        } else if (sw === 'U') {
            // Actualizar Catalogo
            this.displayListCatalogo = false;
            this.displayCrearCatalogo = true;
            this.displayCatalogoArchivo = true;
            this.getCatalogoById(id);
        } else if (sw === 'V') {
            //Volver al listado de catalogos
            this.displayListCatalogo = true;
            this.displayCrearCatalogo = false;
            this.cargarCatalogos();
        }
    }

    validarArea(value) {
        this.disableCatalogoEditorialISMN = !(this.catalogo.AreaId == 5840);
        this.cargarPractica(value);
        this.cargarRetencion();
    }

    borrarCatalogo(id) {
        Swal.fire({
            title: 'Borrar Registro?',
            text: 'Ese proceso no se podra revertir!',
            // type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Borrar Esto!',
        }).then((result) => {
            if (result.value) {
                this._catalogoService.deleteCatalogo(id).subscribe(
                    (result) => {
                        if (result.OperacionExitosa) {
                            Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
                        }
                        else {
                            Swal.fire('ERROR!', 'Ocurrio un error al borrar el registro', 'error');
                        }
                        this.cargarCatalogos();
                    },
                    (error) => {
                        // this.error = error;
                        // if (error.statusText === 'Unauthorized') {
                        //     this.servicePNotify.error('SIG', 'Se perdio la sesión, por favor loguearse de nuevo', '');
                        //     this.authenticationService.logout().subscribe(response => { });
                        //     this.storageService.logout();
                        // }
                        // swal('Agente Creado', resp.Mensaje, 'success' );
                        console.log(<any>error);
                    }
                );

            }
        });
    }


    cargarAreas() {

        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorId(3082)
            .subscribe(
                (result) => {
                    this.areaLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarCategoria() {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorId(3101)
            .subscribe(
                (result) => {
                    this.categoriaLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarPractica(value) {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorIdPadreTabla(this.catalogo.AreaId, 3107)
            .subscribe(
                (result) => {
                    const resultArray = result.Lista.map((elm) => ({
                        id: elm.idValorReferencia,
                        text: elm.nombre,
                    }));
                    this.PracticaLista = new DataSource({
                        store: new ArrayStore({
                            key: 'id',
                            data: resultArray,
                        }),
                    });
                    if (value) {
                        this.datos = value;
                    }
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarRetencion() {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorIdPadreTabla(this.catalogo.AreaId, 3110)
            .subscribe(
                (result) => {
                    this.retencionLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarSubCategoria() {

        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorIdPadre(this.catalogo.CategoriaId)
            .subscribe(
                (result) => {
                    this.subCategoriaLista = result.Lista;

                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarSubSubCategoria() {

        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorIdPadre(this.catalogo.SubcategoriaId)
            .subscribe(
                (result) => {
                    this.subSubCategoriaLista = result.Lista;

                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarTipoPublicacion() {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorId(3102)
            .subscribe(
                (result) => {
                    this.tipoPublicacionLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarAmbito() {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorId(3103)
            .subscribe(
                (result) => {
                    this.ambitoLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarTipoArchivos() {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorId(3104)
            .subscribe(
                (result) => {
                    this.tipoArchivoLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    cargarEstados() {
        this._tipoReferenciaService
            .getValorReferenciaPorTipoValorId(3106)
            .subscribe(
                (result) => {
                    this.estadoLista = result.Lista;
                },
                (error) => {

                    console.log(<any>error);
                }
            );
    }

    addIdParameter(e) {
        this.url = URL_SERVICIOS + '/ApiRest/Catalogo/MediaUploadCatalogoDocumento';
    }

    subidaCompleta(e) {
        if (e.request.status === 201) {
            const obj = JSON.parse(e.request.response);
            console.log(obj.Message.split('|')[1]);
            this.catalogo.ArchivoLink = obj.Message.split('|')[1];
            this.urlImagen =
                URL_SERVICIOS + '/Medios/CatalogoEditorial/' + obj.Message.split('|')[1];
            setTimeout(() => {
                Swal.fire(
                    'Archivo cargado',
                    'Los datos se han subido correctamente!',
                    'success'
                );
            }, 15);
        } else {
            setTimeout(() => {
                Swal.fire(
                    'ERROR',
                    'Los datos NO se han subido correctamente!',
                    'success'
                );
            }, 15);
        }
    }

    descargarArchivo(id: number) {
        if (id !== null) {
            this.loading = true;
            this._catalogoService.downloadMediosDocumentoById(id).subscribe(
                (res) => {
                    const url = window.URL.createObjectURL(res.body);                    
                    const fileName = res.headers.get('content-disposition').split(';')[1].split('filename')[1].split('=')[1].trim();
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = fileName;
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                    this.loading = false;
                },
                (error) => {
                    this.error = error;
                    this.loading = false;
                    console.log(<any>error);
                }
            );
        }
    }


    //#region Archivo
    inicializarArchivo() {
        this.archivo = {
            CatalogoEditorialArchivoId: null,
            CatalogoEditorialId: null,
            ArchivoId: null,
            ArchivoTitulo: '',
            ArchivoTipoId: null,
            ArchivoLink: '',
            ArchivoDuracionAudio: '',
            ArchivoNombreArchivo: '',
            ArchivoFechaRegistro: '',
            ArchivoUsuarioIdCreacion: null,
            ArchivoEstadoId: null,
            NombreTipoArchivo: '',
        };
        this.tipoArchivoLista = [];
    }

    showAccionArchivo(ArchivoId: number, accion: string) {
        this.inicializarArchivo();
        this.valueArchivo = [];

        this.cargarTipoArchivos();
        if (accion === 'U') {
            this.getcatalogoArchivoByArchivoId(ArchivoId);
        }
        this.popupVisibleRegistroArchivo = true;
        this.accionArchivo = accion;
    }

    getcatalogoArchivoByArchivoId(ArchivoId) {

        this._catalogoService.getArchivoById(ArchivoId).subscribe(
            (result) => {
                this.archivo = result;
                // debugger;
                // this.urlArchivo = this.archivo.Fotografia; pendiente

            },
            (error) => {

                console.log(<any>error);
            }
        );
    }

    borrarArchivo(Id) {
        Swal.fire({
            title: 'Borrar Archivo?',
            text: 'Ese proceso no se podra revertir!',
            // type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, Borrar Esto!',
        }).then((result) => {
            if (result.value) {
                this._catalogoService.deleteCatalogoArchivo(Id).subscribe(
                    (result) => {
                        this.cargarArchivoPorCatalogoId(this.catalogo.CatalogoEditorialId);
                    },
                    (error) => {

                        console.log(<any>error);
                    }
                );
                Swal.fire('Borrado!', 'Este registro fue borrado.', 'success');
            }
        });
    }

    getCatalogoById(id) {
        // debugger;
        this._catalogoService.getCatalogoById(id).subscribe(
            (result) => {
                this.catalogo = result;

                this.cargarArchivoPorCatalogoId(this.catalogo.CatalogoEditorialId);
                this.cargarSubCategoria();
                this.validarArea(this.catalogo.Practica);
                this.cargarSubSubCategoria();


                this.urlImagen = URL_SERVICIOS + 'Medios/CatalogoEditorial/' + this.catalogo.ArchivoLink;

            },
            (error) => {

                console.log(<any>error);
            }
        );
    }

    cargarArchivoPorCatalogoId(CatalogoId) {
        this._catalogoService.getArchivoByCatalogo(CatalogoId).subscribe((resp: any) => {
            this.listaCatalogoArchivo = resp.Lista;
        });
    }

    onSubmit(isValid: any, paso: string) {
         // debugger;
        if (isValid) {

            this.catalogo.CuentaUsuarioIdCreacion = this.usuId;

            this.catalogo.Practica = [];

            for (let i = 0; i < this.selectedItemsPractica.length; i++) {
                this.catalogo.Practica.push(
                    this.selectedItemsPractica[i].id
                );
            }


            this._catalogoService.crearCatalogo(this.catalogo).subscribe((resp) => {
                // debugger;
                if (this._catalogoService.CatalogoId != 0) {
                    this.catalogo.CatalogoEditorialId = this._catalogoService.CatalogoId;
                }


                if (this.catalogo.CatalogoEditorialId != 0 && this.catalogo.CatalogoEditorialId != null && this._catalogoService.OperacionExitosa) {

                    this.displayCatalogoArchivo = true;
                }
            });



        }
    }

    addIdParameterArchivo(e) {
        this.urlArchivo = URL_SERVICIOS + '/ApiRest/Catalogo/MediaUploadCatalogoDocumento';
    }

    subidaCompletaArchivo(e) {
        if (e.request.status === 201 || e.request.status === 200) {
            const obj = JSON.parse(e.request.response);
            console.log(obj.Message.split('|')[1]);
            this.archivo.ArchivoNombreArchivo = obj.Message.split('|')[1];
            // this.urlImagen =
            //     URL_SERVICIOS + '/Medios/CatalogoEditorial/' + obj.Message.split('|')[1];
            setTimeout(() => {
                Swal.fire(
                    'Archivo cargado',
                    'Los datos se han subido correctamente!',
                    'success'
                );
            }, 15);
        } else {
            setTimeout(() => {
                Swal.fire(
                    'ERROR',
                    'Los datos NO se han subido correctamente!',
                    'success'
                );
            }, 15);
        }
    }

    popupHidingRegistroArchivo() {
        this.popupVisibleRegistroArchivo = false;
    }

    onSubmitRegistroArchivo(isValid: any) {
        // debugger;
        if (isValid) {

            if (this.archivo.ArchivoLink === '') {
                if (this.archivo.ArchivoNombreArchivo === '') {
                    Swal.fire(
                        'Mensaje',
                        'Debe Seleccionar el archivo y hacer clic en el boton subir archivo', 'error'
                    );
                    return;
                }
            }
            this.archivo.CatalogoEditorialId = this.catalogo.CatalogoEditorialId;

            // if (this.accionProyectoDocumento === 'C') {
            this.archivo.ArchivoUsuarioIdCreacion = this.usuId;
            //}

            // const iddoc = this.listaProyectoDocumento.findIndex(e => e.ProyectoTipoDocumentoId == this.documento.ProyectoTipoDocumentoId && !(e.ArchivoId == this.documento.ArchivoId))

            // if (iddoc >= 0) {
            //     Swal.fire(
            //         'Mensaje',
            //         'No se pueden cargar dos documentos con el mismo tipo de documento', 'error'
            //     );
            //     return;
            // }

            this._catalogoService.addCatalogoArchivo(this.archivo).subscribe((resp) => {
                this.popupVisibleRegistroArchivo = false;
                this.cargarArchivoPorCatalogoId(this.catalogo.CatalogoEditorialId);
                // this.showAccionProyecto(this.proyecto.ProyectoId, 'U');
            });
        }
    }

}