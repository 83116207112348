import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-audiovisual-sonoro',
  templateUrl: './audiovisual-sonoro.component.html',
  styleUrls: ['./audiovisual-sonoro.component.css','../../../../assets/css/css.css']
})
export class AudiovisualSonoroComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
